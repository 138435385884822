import React, { useEffect } from "react";
import EB2testimony from "../ebnw/EB2testimony";
import FaqSection from "../../views/FaqSection";
import Footer from "../../containers/Footer";
import Features from "../Features";
import { ClarityTop } from "./clarityTop";
import NavBar from "../../containers/NavBar";
import ClarityVisaTypes from "./ClarityVisaTypes";
import ClarityPricing from "./clarityPricing";

function ClarityCallHome() {
  useEffect(() => {
    const urlparams = new URLSearchParams(window.location.search);
    const referalCode = urlparams.get("code");

    if (referalCode) {
      localStorage.setItem("referalCode", referalCode);
    }
  }, []);

  return (
    <div>
      <NavBar />
      <ClarityTop />
      <ClarityVisaTypes />
      <ClarityPricing
        title={
          <>
            <h1 className="text-3xl text-black font-fontSemi text-center">
              Pricing Plan
            </h1>
            <p className="text-center text-lg font-fontReg text-[#2B5219]">
              Our pricing plans are designed to meet the needs of everyone!
            </p>
          </>
        }
      />
      <EB2testimony
        title={
          <>
            <h1 className="text-3xl text-white font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-white text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <FaqSection />
      <Footer />
    </div>
  );
}

export default ClarityCallHome;
