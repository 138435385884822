import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import CurlyL from "../assets/images/curlybgleft.png";
import CurlyR from "../assets/images/curlybgright.png";
import DownloadApp from "../views/DownloadApp";
import MeetTheTeam from "../views/MeetTheTeam";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";
import { EngineeringTeam, GlobalServiceDeliveryTeam, OperationsTeam, ProductCreativeTeam } from "../constant/TeamsDetail";
import TwitterLogo1 from "../assets/logo-icons/twitter-logo1.svg";
import LinkedinLogo1 from "../assets/logo-icons/linkedin-logo1.svg";
import { FaXTwitter } from "react-icons/fa6";


const Team = () => {
  return (
    <>
      <NavBar />
      <div className="bg-secondary pb-16 w-full pt-24 lg:py-40 relative overflow-hidden">
        <img src={CurlyL} alt="shape" className="absolute left-0" />
        <img src={CurlyR} alt="shape" className="absolute right-0" />

        <div className="container mx-auto">
          <div className="flex flex-col gap-12 justify-center items-center">
            <div className="lg:w-[40%] w-80% z-30 text-center">
              <h1 className="text-white font-fontReg text-4xl pb-5 tracking-wide">
                <span className="text-[#67A948]">Humans</span> of Vesti
              </h1>
              <p className="text-base font-fontLight text-[#ffffff] tracking-wider">
                Meet some of the team members making what we do at Vesti
                possible
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-28 pb-20">
        <MeetTheTeam />

      {/* Engineering Team */}
        <div className="container mx-auto lg:w-[90%] text-center pt-[6%]">
            <h1 className="font-bold text-[28px] pb-14 text-[#14290A] text-center">Engineering Team</h1>
            <div className="grid justify-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {EngineeringTeam.map((person, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img className="h-[13rem] w-[13rem] object-cover  object-top rounded-full" src={person.img} alt=""/>
                  <div className="pb-3 text-center">
                    <h3 className="text-[#14290A] font-fontReg text-xl">{person.fullName}</h3>
                    <p className="text-[#14290A] font-fontLight text-sm">{person.designation}</p>
                  </div>

                  <div className="flex items-center">
                  <Link to={person.socialLink1}>
                    <img
                      className=" w-[18px]"
                      src={LinkedinLogo1}
                      alt="linkedin-logo"
                    />
                  </Link>
                  </div>
                </div>
              ))}
            </div>
        </div>

        {/* Operations Team */}
        <div className="container mx-auto lg:w-[90%] text-center pt-[10%]">
            <h1 className="font-bold text-[28px] pb-14 text-[#14290A] text-center">Operations Team </h1>
            <div className="grid gap-8 justify-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {OperationsTeam.map((person, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img className="h-[13rem] w-[13rem] object-cover  object-top rounded-full" src={person.img} alt=""/>
                  <div className="pb-3 text-center">
                    <h3 className="text-[#14290A] font-fontReg text-xl">{person.fullName}</h3>
                    <p className="text-[#14290A] font-fontLight text-sm">{person.designation}</p>
                  </div>
                 
                  <div className="flex items-center">
                  <Link to={person.socialLink1}>
                    <img
                      className="w-[18px]"
                      src={LinkedinLogo1}
                      alt="linkedin-logo"
                    />
                  </Link>
                  </div>
                </div>
              ))}
            </div>
        </div>

         {/* Global Service Delivery Team */}
         <div className="container mx-auto lg:w-[90%] text-center pt-[10%]">
            <h1 className="font-bold text-[28px] pb-14 text-[#14290A] text-center">Global Service Delivery Team</h1>
            <div className="grid gap-8 justify-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {GlobalServiceDeliveryTeam.map((person, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img className="h-[13rem] w-[13rem] object-cover  object-top rounded-full" src={person.img} alt=""/>
                  <div className="pb-3 text-center">
                    <h3 className="text-[#14290A] font-fontReg text-xl">{person.fullName}</h3>
                    <p className="text-[#14290A] font-fontLight text-sm">{person.designation}</p>
                  </div>

                  <div className="flex items-center">
                  <Link to={person.socialLink1}>
                    <img
                      className="w-[18px]"
                      src={LinkedinLogo1}
                      alt="linkedin-logo"
                    />
                  </Link>
                  </div>
                </div>
              ))}
            </div>
        </div>

         {/* Product/Creative Team */}
         <div className="container mx-auto lg:w-[90%] text-center pt-[10%]">
            <h1 className="font-bold text-[28px] pb-14 text-[#14290A] text-center">Product/Creative Team </h1>
            <div className="grid gap-8 justify-items-center sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
              {ProductCreativeTeam.map((person, index) => (
                <div key={index} className="flex flex-col items-center">
                  <img className="h-[13rem] w-[13rem] object-cover  object-top rounded-full" src={person.img} alt=""/>
                  <div className="pb-3 text-center">
                    <h3 className="text-[#14290A] font-fontReg text-xl">{person.fullName}</h3>
                    <p className="text-[#14290A] font-fontLight text-sm">{person.designation}</p>
                  </div>
                 
                  <div className="flex items-center">
                  <Link to={person.socialLink1}>
                    <img
                      className="w-[18px]"
                      src={LinkedinLogo1}
                      alt="linkedin-logo"
                    />
                  </Link>
                  </div>
                </div>
              ))}
            </div>
        </div>

      </div>
      <div className="w-[80%] bg-secondary mb-24 mx-auto rounded-[30px]">
        <div className="container mx-auto text-center py-14">
          <h1 className="text-[#7F8CE5] text-lg font-fontReg">
            COME WORK WITH US
          </h1>
          <p className="text-[#ffffff] pt-2 pb-10 text-2xl leading-[30px] font-fontLight">
            What we do is very important. Come and do meaningful work, and help
            drive our mission of making enterprising easier and more accessible
            to everyone.
          </p>
          <Link to="#">
            <Button className="bg-vestigreen p-4 capitalize w-[35%] font-fontReg hover:bg-headcol">
              View open roles
            </Button>
          </Link>
        </div>
      </div>
      <DownloadApp />
      <Footer />
    </>
  );
};

export default Team;
