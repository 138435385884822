import React from "react";
import { StartRev } from "./CanCareVisaData";
import { Testimony } from "../../constant/TestimonialsData";

const Users = () => {
  return (
    <div className="mb-4 md:flex md:flex-col md:items-center px-4 md:px-[3%] xl:px-[5%] xlg:px-20 xxl:px-[6%] py-4 sm:py-6 md:py-8 xl:py-10 xlg:py-10">
      <div className="bg-vestigreen rounded-md my-6 text-[#FFFFFF] mt-2 xlg:mt-4 p-4 xlg:p-10">
        <h1 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] font-bold text-center my-4">
          What Users Are Saying
        </h1>
        <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4 p-1 sm:p-2 md:p-4 xl:p-6 xlg:p-6">
          {Testimony.map(
            ({ image, name, date, testimony, background }, index) => {
              return (
                <div key={index} className={`bg-[${background}] rounded-md`}>
                  <div className="p-4">
                    <div className="flex items-center gap-2">
                      <img src={image} alt="" className="w-[40px] h-[40px] rounded-full" />
                      <p className="text-[#14290A]">{name}</p>
                      <div className="">
                        <h6 className="text-[#14290A] text-[20px] font-normal">
                          {/* {company} */}
                        </h6>
                      </div>
                    </div>
                    <p className="text-[12px] text-[#4F4F4F] mt-2">{date}</p>

                    <p className="text-[#4F4F4F] pt-4 text-sm">{testimony}</p> 
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
