import greenTick from "../../assets/CanadianCareGiver/greenTick.png";
import avatarImg from "../../assets/Partnership-Img/AvatarCard.png";

export const BenefitDataF = [
  {
    img: greenTick,
    title: "Trusted Partnerships",
    details:
      "Collaborate with reputable lenders and landlords to secure your housing.",
  },
  {
    img: greenTick,
    title: "Path to Stability",
    details:
      "Build a secure foundation for you and your family in your new home.",
  },
  // {
  //   img: greenTick,
  //   title: "Cultural Experience",
  //   details:
  //     "Immerse yourself in Canada’s diverse culture and welcoming communities.",
  // },
];

export const BenefitDataS = [
  {
    img: greenTick,
    title: "Affordable Solutions",
    details: "Achieve your housing goals without breaking the bank.",
  },
  {
    img: greenTick,
    title: "Expert Guidance",
    details:
      "Receive personalized support from our team to navigate every step of your journey with ease.",
  },
  // {
  //   img: greenTick,
  //   title: "Social Benefits",
  //   details:
  //     "Access social programs that support your well-being and long-term stability.",
  // },
];

//   Users

export const StartRev = [
  {
    img: avatarImg,
    company: "Damola Oni",
    name: "Jane Smith,",
    location: "XYZ Accelerator",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F7FFF3",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Mainstack",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F6F7FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Wale Yusuff,",
    location: "AltSchool Africa",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#FBF3FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Gova",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F6F7FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Jane Smith,",
    location: "XYZ Accelerator",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#FBF3FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Jane Smith,",
    location: "XYZ Accelerator",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F7FFF3",
  },
];
