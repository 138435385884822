import React, { useState } from "react";
import { SchoolsData } from "../constant/SchoolsData";
import searchIcon from "../assets/images/eva_search-fill.svg";
import "./SchoolsSearch.scss";
// import { useLocation } from "react-router-dom";

// import { Table } from "antd";

const SchoolsSearch = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [dataSource, setDataSource] = useState(SchoolsData);
  // const [pageNumber, setpageNumber] = useState(1);

  // const totalItems = SchoolsData.length;

  function globalSearch(SchoolsData, searchQuery) {
    searchQuery = searchQuery?.trim().toLowerCase();

    if (searchQuery === "") {
      return SchoolsData;
    }

    return SchoolsData.filter((item) => {
      return columns.some((column) => {
        const cellValue = item[column.dataIndex];
        if (
          cellValue &&
          cellValue.toString().toLowerCase().includes(searchQuery)
        ) {
          return true;
        }
        return false;
      });
    });
  }

  const handleSearch = () => {
    const trimmedSearchQuery = searchQuery?.trim().toLowerCase();
    if (trimmedSearchQuery === "") {
      setDataSource(SchoolsData);
    } else {
      const filtered = globalSearch(SchoolsData, trimmedSearchQuery);
      setDataSource(filtered);
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const columns = [
    {
      title: "School Name",
      dataIndex: "name",
      width: 200,
      key: "name",
    },
    {
      title: "Region",
      dataIndex: "address",
      width: 200,
      key: "address",
    },
    {
      title: "Country",
      dataIndex: "country",
      width: 100,
      key: "country",
    },
  ];

  return (
    <div className="w-full container mx-auto ">
      <div className="w-full lg:w-[720px]  h-[70px] mt-6 border-[0.5px] border-[#676D7E] mx-auto rounded-[5px] flex flex-col justify-center">
        <div className="flex justify-between items-center">
          <div className="flex px-8">
            <img src={searchIcon} alt="" />
            <input
              type="text"
              placeholder="Search for School"
              value={searchQuery || " "}
              onChange={handleChange}
              className="p-2 rounded-md mx-2 w-full outline-none focus:border-vestigreen"
            />
          </div>
          <hr className="border border-slate-200 h-[25px]" />

          <button
            // onClick={() => {}}
            onClick={handleSearch}
            className="bg-[#66A848] w-[150px] h-[56px] text-white rounded-[5px] mr-2"
          >
            search
          </button>
        </div>
      </div>
      <div className="bg-white mt-12">
        <div>
          <p className="font-bold text-2xl mb-4">
            Schools we support ({SchoolsData.length})
          </p>
          {/* <p className="font-semibold text-xl mb-4">
            Over 400 supported Universities from United State, United Kingdom,
            Australia, Netherlands, Canada, Hong Kong ...
          </p> */}
          {/* Table */}
          <div className="overflow-x-auto mb-6 flex">
            <div className="table-auto w-full">
              <div className="Schoolscontainer my-12 ">
                <div className="flex justify-between w-[750px] bg:w-full mt-6 cursor-pointer ">
                  <p className="font-[600] w-[400px]  bg:w-[50%] text-[18px]">
                    School Name
                  </p>
                  <p className="font-[600] w-[200px]  bg:w-[30%] text-[18px]">
                    Country
                  </p>
                  <p className="font-[600] w-[150px]  bg:w-[20%] text-[18px]">
                    Region
                  </p>
                </div>
                {dataSource.map((data) => {
                  return (
                    <div className="flex justify-between w-[750px] bg:w-full overflow-scroll mt-6 cursor-pointer ">
                      <p className="w-[400px]  bg:w-[50%] ">{data?.name}</p>
                      <p className="w-[200px]  bg:w-[30%]">{data?.country}</p>
                      <p className="w-[150px]  bg:w-[20%]">{data?.address}</p>
                    </div>
                  );
                })}

                {Object.keys(dataSource).length === 0 ? (
                  <div className="text-center mt-14 text-red-500">
                    School not found
                  </div>
                ) : (
                  <div></div>
                )}
              </div>

              {/* <Table
                dataSource={dataSource}
                columns={columns}
                rowKey={(record) => record.id}
                pagination={{
                  current: pageNumber,
                  total: totalItems,
                  pageSize: 15,
                  onChange: (pageNumber) => {
                    setpageNumber(pageNumber);
                  },
                }}
                scroll={{
                  x: 2000,
                  y: 500,
                }}
              /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolsSearch;
