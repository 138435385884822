import { Link } from "react-router-dom";
import Techstars from "../assets/logo-icons/techstars1.svg";
import Techcity from "../assets/logo-icons/techcity1.svg";
import Bloomberg from "../assets/logo-icons/bloomberg1.svg";
import { openLink } from "../helpers/helpers";
import techCity from '../assets/CanadianCareGiver/TechCity Logo.svg'
import investO from '../assets/CanadianCareGiver/inves.svg'
import investT from '../assets/CanadianCareGiver/inves2.svg'

const WebBanner = ({
  title,
  details,
  buttonText,
  featured,
  image,
  imgWidth,
  link,
}) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-[45%] z-30">
            {title}
            <p className="lg:w-[90%] text-lg font-fontLight pb-12 text-[#ffffff]">
              {details}
            </p>
            <div className="bg-[#67A948] w-[80%] sm:w-[55%] font-fontReg text-center transition duration-700 ease-in-out hover:bg-[#3E6F26] p-5 rounded-xl mb-[70px] text-[#ffffff]">
              <Link
                // to={link}
                onClick={() => window.open(link, "_blank")}
              >
                {buttonText}
              </Link>
            </div>
            {/* {featured} */}
            <div className="md:mt-[7%] mt-[5%] md:mb-6 mb-0 flex items-center xlg:gap-2 gap-1">
            <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
              As featured in:
            </p>
            <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investO}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                    "_blank"
                  )
                }
              />

              <img
                className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                src={techCity}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                    "_blank"
                  )
                }
              />

              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investT}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
          </div>
          <div className={`lg:w-[${imgWidth}] z-30`}>
            <img className="w-full" src={image} alt="vesti-img" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WebBanner;

export const WebBanner2 = ({
  title,
  details,
  buttonText,
  image,
  imgWidth,
  onClick,
  link,
}) => {
  return (
    <>
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="lg:w-[45%] z-30">
            {title}
            <p className="lg:w-[90%] text-lg font-fontLight pb-12 text-[#ffffff]">
              {details}
            </p>
            <div className="bg-[#67A948] w-[80%] sm:w-[55%] font-fontReg text-center transition duration-700 ease-in-out hover:bg-[#3E6F26] p-5 rounded-xl mb-[70px] text-[#ffffff]">
              <Link
                // to={link}
                onClick={() => onClick()}
              >
                {buttonText}
              </Link>
              {/* <p onClick={()=>onClick()}>{buttonText}</p> */}
            </div>
            {/* {featured} */}
            <div className="flex flex-wrap justify-center opacity-80 gap-8 pb-8 lg:pb-0">
              <img
                className=""
                src={Techstars}
                alt="techstars"
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022"
                  )
                }
              />
              <img
                className=""
                src={Techcity}
                alt="techcity"
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/"
                  )
                }
              />
              <img
                className=""
                src={Bloomberg}
                alt="bloomberg"
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians"
                  )
                }
              />
            </div>
          </div>
          <div className={`lg:w-[${imgWidth}] z-30`}>
            <img className="w-full" src={image} alt="vesti-img" />
          </div>
        </div>
      </div>
    </>
  );
};
