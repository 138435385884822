import CardBg from "../assets/images/card-bg.png";
import Button from "../components/Button";
import { openLink } from "../helpers/helpers";
import techCity from '../assets/CanadianCareGiver/TechCity Logo.svg'
import investO from '../assets/CanadianCareGiver/inves.svg'
import investT from '../assets/CanadianCareGiver/inves2.svg'

const VirtualVisaCard = () => {
  return (
    <div className="relative bg-secondary pt-12 lg:pt-36 text-white">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row">
          <div className="pt-16">
            <h1 className="text-4xl lg:text-4xl xl:text-5xl font-fontSemi pb-5 lg:w-[70%] !leading-[60px]">
              The GlobalGeng <span className="text-[#67A948]">Virtual</span>{" "}
              <span className="text-[#67A948]">Dollar</span> Card
            </h1>
            <p className="text-lg pb-12 lg:w-[60%] font-fontReg">
              Talking about convenience? This is the card for you! Virtual
              Dollar Debit Card that can be used at anytime and anywhere in the
              world.
            </p>
            <Button link="#" CTA="Create your card" styles="p-4" width="35%" />
            <div className="md:mt-[7%] mt-[5%] md:mb-6 mb-0 flex items-center xlg:gap-2 gap-1">
            <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
              As featured in:
            </p>
            <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investO}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                    "_blank"
                  )
                }
              />

              <img
                className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                src={techCity}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                    "_blank"
                  )
                }
              />

              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investT}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
          </div>
          <div className="">
            <img className="" src={CardBg} alt="featured" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualVisaCard;
