import React, { useState, useEffect } from "react";
import eb2Img from "../../assets/clarityCall/eb2banner.png";
import woman from "../../assets/clarityCall/o1claritybanner.png";
import eb1 from "../../assets/clarityCall/eb1banner.png";
import techCity from '../../assets/CanadianCareGiver/TechCity Logo.svg'
import investO from '../../assets/CanadianCareGiver/inves.svg'
import investT from '../../assets/CanadianCareGiver/inves2.svg'
import { Link as AnchorLink } from "react-scroll";
import { openLink } from "../../helpers/helpers";

export const Claritycall = (props) => {
  const [img, setImg] = useState(null);

  useEffect(() => {
    switch (props.visaType) {
      case "0-1":
        return setImg(woman);

      case "EB-1":
        return setImg(eb1);

      case "EB-2":
        return setImg(eb2Img);

      default:
        return setImg(woman);
    }
  }, [props.visaType]);

  return (
    <div className="bg-secondary w-full pt-24 lg:pt-28 text-white">
      <div className="container mx-auto">
        <div className="grid lg:grid-cols-2 gap-20 items-center">
          <div className="col-span-1 mt-10 lg:mt-0 lg:w-[80%]">
            <h1 className="text-2xl font-fontSemi mt-20 lg:mt-0 lg:text-3xl pb-5  lg:font-[500] !leading-[30px] lg:!leading-[40px]">
              <span Book className="text-[#f4f7f3]">
                Book a {props.visaType} {props.visaType === "EB-2" ? "NIW" : ""}{" "}
                Visa Clarity Call Session{" "}
              </span>
            </h1>

            <p className="text-[15px] font-[500] text-[#f4f7f3] pb-6 ">
              Schedule a clarity call with our experienced experts who will
              guide you through every step of your move to your dream country.
              From visa intricacies to cultural assimilation, our specialists
              are here to ensure your transition is smooth and successful.
            </p>
            <AnchorLink
              spy={true}
              smooth={true}
              to="bookcallSection"
              className="normal-case text-white bg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px] rounded-lg cursor-pointer"
            >
              Book a Clarity Session
            </AnchorLink>
            <div className="md:mt-[14%] mt-[5%] md:mb-0 mb-0 flex items-center xlg:gap-2 gap-1">
            <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
              As featured in:
            </p>
            <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investO}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                    "_blank"
                  )
                }
              />

              <img
                className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                src={techCity}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                    "_blank"
                  )
                }
              />

              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investT}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
          </div>
          <div className="">
            <img className=" h-[35rem]" src={img} alt="Big Smile" />
          </div>
        </div>
      </div>
    </div>
  );
};
