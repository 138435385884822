import React from 'react'
import imgUp from '../../assets/Event-Img/imgUp.png'
import imgDown from '../../assets/Event-Img/imgDown.png'
import { Simplemodal } from '../helpers/modal/simplifiedmodal'
import errorsvg from "../../assets/logo-icons/oops.svg";
import { Success } from '../helpers/success/success';

export const Updates = (props) => {

  const handleEmailChange = (e) => {

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(e.target.value)) {
      props.setEmail(e.target.value)
      props.setErrorEmail(true)
    } else {
      props.setEmail(e.target.value)
      props.setErrorEmail(false)

    }
  }

  return (
    <div className='bg-[#F7FFF3] h-full relative mt-8 py-20 px-4 md:px-[6%] overflow-hidden'>
      
    <div className='flex flex-col justify-center items-center'>
        <div className='w-full lg:w-[400px]'>
            <h1 className='font-bold text-[26px] sm:text-[30px] text-center'>Don't Miss an Event!</h1>
            <p className=' text-center mt-2 mb-6'>Enter your email to subscribe and be the first to receive updates about our events</p>
            <div className='text-[14px] gap-4 flex w-full'>
                <input className='bg-[#F5F5F5] p-2 px-6 w-[70%] rounded-md py-4 mt-2 outline-none' type='email' placeholder='Enter your email' value={props.email}
                  onChange={handleEmailChange}/>
                <button disabled={props.email && !props.errorEmail ? false : true} className={`bg-vestigreen text-white p-2 px-6 w-[30%] rounded-md ${props.email && !props.errorEmail ?'cursor-pointer':'cursor-not-allowed'}` } onClick={()=>{props.email && !props.errorEmail ? props.handleSubmit() : props.setSubscriptionModal(false)}}>Subscribe</button>
            </div>
              {props.errorEmail && props.email? <p className='text-[12px] text-red-800 font-[600]'>Invalid Email</p> : <p></p> }
            </div>
    </div>

            <img src={imgUp} alt="" className='flex justify-start w-[80px] lg:w-auto absolute top-0 -left-5'/>
            <img src={imgDown} alt="" className='fles justify-end absolute w-[80px] lg:w-auto bottom-0 -right-5' />

            <Simplemodal 
          open={props.subscriptionModal} 
          onClick={() => {props.setSubscriptionModal(false)}}
          >
            {props.message === 'You have previously subscribed' || props.message === 'An Error Occured' ? <><Success
            image={errorsvg}
            type="error"
            title={props.message}
            subtitle=''
            button="Close"
            onClick={() => {
              props.setSubscriptionModal(false)
            }}
          /></> : <><Success
          title="Events subscribed successfully"
          subtitle=" "
          button="Close"
          onClick={() => {
            props.setSubscriptionModal(false)
            }}
        /></>}
          </Simplemodal>
    </div>
  )
}
