import GGCard from "../assets/images/ggmastercard.png";
import { Button } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import techCity from '../assets/CanadianCareGiver/TechCity Logo.svg'
import investO from '../assets/CanadianCareGiver/inves.svg'
import investT from '../assets/CanadianCareGiver/inves2.svg'
import { openLink } from "../helpers/helpers";

const GlobalGCard = () => {
  return (
    <div className="relative bg-secondary py-24 lg:pt-36 text-white">
      <div className="container mx-auto">
        <div className="flex flex-col gap-20 justify-between items-center lg:flex-row">
          <div className="lg:w-[45%]">
            <h1 className="text-4xl lg:text-4xl xl:text-5xl font-fontSemi pb-5 lg:w-[100%] lg:!leading-[60px] sm:!leading-[40px]">
              The Vesti <span className="text-[#67A948]">GlobalGeng</span>{" "}
              Mastercard
            </h1>
            <p className="text-lg pb-12 lg:w-[90%] md:w-[80%] font-fontLight">
              Get all the benefits of our variety of cards - spend online, at
              major airports in-stores, and abroad while saving on currency
              conversion fees, with the extra layer of security of the our
              digital cards.
            </p>
            <div className="pb-10">
              <Link
                // to="https://app.wevesti.com/bank"
                onClick={() =>
                  window.open("https://app.wevesti.com/cards", "_blank")
                }
              >
                <Button className="capitalize w-[80%] sm:w-[60%] lg:w-[50%] bg-vestigreen hover:bg-headcol p-5 text-base">
                  Create your card
                </Button>
              </Link>
            </div>
            <div className="md:mt-[7%] mt-[5%] md:mb-6 mb-0 flex items-center xlg:gap-2 gap-1">
            <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
              As featured in:
            </p>
            <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investO}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                    "_blank"
                  )
                }
              />

              <img
                className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                src={techCity}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                    "_blank"
                  )
                }
              />

              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investT}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
          </div>
          <div className="lg:w-[60%]">
            <img className="w-full" src={GGCard} alt="featured" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GlobalGCard;
