import React from "react";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";
import { Link } from "react-router-dom";
import errorsvg from "../assets/logo-icons/oops.svg";

export const MaintenancePage = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 text-[#2B5219]">
      <div className="md:max-w-[40%] max-w-[90%] p-6  text-center ">
        <img src={errorsvg} alt className="w-[80%] xxl:w-[60%] mx-auto" />
        <p className="text-[18px] mb-4 mt-6">
          This page is currently under maintenance and will be back shortly. We
          apologize for the inconvenience.
        </p>

        <a
          href="https://wevesti.com/migration-fries"
          className="inline-block px-6 py-2 text-[14px] text-white bg-vestigreen  rounded-md transition-colors"
        >
          Go Back
        </a>
      </div>
    </div>
  );
};
