import React from "react";
import covenLogo from "../../assets/visaForm/covenlabsFullLogo.svg";
import InfraNova from "../../assets/H1bPetitionImg/infracomLogo.png";
import { FaArrowRight } from "react-icons/fa";
import OretuLogo from "../../assets/H1bPetitionImg/oretuuu.png";
import RexLogo from "../../assets/H1bPetitionImg/rexLogo-removebg-preview.png";
import { useParams } from "react-router-dom";

const data = [
  {
    logo: covenLogo,
    companyName: "Coven Work",
    text: "Coven works is a leading data science and AI company dedicated to helping organizations recruit and build their staff with cutting-edge technology and expertise.",
    link: "https://joincovenworks.com",
    id: "1",
  },
  {
    logo: InfraNova,
    companyName: "Infra Nova Inc",
    text: "InfraNova uses its proprietary technology to drive growth for other technology and non-technology companies . Consulting for a variety of business and government clients in Africa, United Kingdom and the US.",
    link: "https://infranova.us/",
    id: "2",
  },
];

const referrerData = [
  {
    logo: covenLogo,
    companyName: "Coven Work",
    text: "Coven works is a leading data science and AI company dedicated to helping organizations recruit and build their staff with cutting-edge technology and expertise.",
    link: "https://joincovenworks.com",
    id: "1",
  },
  {
    logo: InfraNova,
    companyName: "Infra Nova Inc",
    text: "InfraNova uses its proprietary technology to drive growth for other technology and non-technology companies . Consulting for a variety of business and government clients in Africa, United Kingdom and the US.",
    link: "https://infranova.us/",
    id: "2",
  },

  {
    logo: "",
    companyName: "Intensify Placements Africa",
    text: "Intensify Placements Incorporated is a data science and analysis consulting firm empowering North American enterprises to make smarter, data-driven decisions. We specialize in delivering actionable insights, predictive models, and custom analytics solutions to optimize business performance and drive growth.",
    link: "",
    id: "3",
    ref: "Intensify Placements Africa",
  },
  {
    logo: OretuLogo,
    companyName: "Oretu LLC",
    text: "Oretu is a full service digital marketing consulting agency that focuses on helping direct-to-consumer brands scale beyond six and seven figures using comprehensive digital advertising. Our services are designed to drive significant business growth by using data and artificial intelligence in targeting the right audience and optimizing campaigns to drive a positive return on ad spend.",
    link: "https://www.oretu.agency/",
    id: "4",
    ref: "Oretu LLC",
  },
  {
    logo: RexLogo,
    companyName: "Rex Technologies Inc",
    text: "Rex Music is a genre-focused streaming company, catering to a global audience of over 2.5 billion who seek a curated experience within their preferred musical genre. Rex is committed to spotlighting emerging talent across diverse genres, offering pathways to global recognition while enriching the musical landscape accessible to audiences worldwide.",
    link: "https://rexapp.org/",
    id: "5",
    ref: "Rex Technologies Inc",
  },
];

const otherCompanies = [
  {
    logo: "",
    companyName: "Intensify Placements Africa",
    text: "Intensify Placements Incorporated is a data science and analysis consulting firm empowering North American enterprises to make smarter, data-driven decisions. We specialize in delivering actionable insights, predictive models, and custom analytics solutions to optimize business performance and drive growth.",
    link: "https://joincovenworks.com",
    id: "1",
    ref: "Intensify Placements Africa",
  },
  {
    logo: OretuLogo,
    companyName: "Oretu LLC",
    text: "Oretu is a full service digital marketing consulting agency that focuses on helping direct-to-consumer brands scale beyond six and seven figures using comprehensive digital advertising. Our services are designed to drive significant business growth by using data and artificial intelligence in targeting the right audience and optimizing campaigns to drive a positive return on ad spend.",
    link: "https://infranova.us/",
    id: "2",
    ref: "Oretu LLC",
  },
  {
    logo: RexLogo,
    companyName: "Rex Technologies Inc",
    text: "Rex Music is a genre-focused streaming company, catering to a global audience of over 2.5 billion who seek a curated experience within their preferred musical genre. Rex is committed to spotlighting emerging talent across diverse genres, offering pathways to global recognition while enriching the musical landscape accessible to audiences worldwide.",
    link: "https://rexapp.org/",
    id: "3",
    ref: "Rex Technologies Inc",
  },
];

export const RefFillingCompanies = (props) => {
  let { referrer } = useParams();

  return (
    <div className="px-4 md:px-8 py-7  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-10 w-full">
      <p
        style={{
          color: "#62943B",
          fontWeight: "600",
          fontSize: "20px",
          textAlign: "center",
          width: "100%",
          paddingBottom: "20px",
        }}
      >
        About our Filing Companies
      </p>

      <div
        className={`${
          referrer
            ? "grid grid-cols-1 sm:grid-cols-2 bg:grid-cols-3 gap-[20px]"
            : "flex flex-col bg:flex-row mt-[10px] gap-[20px]"
        }`}
      >
        {referrer
          ? referrerData.map(({ logo, id, text, companyName, link }) => {
              return (
                <div
                  style={{
                    background: `${
                      companyName === "Coven Work" ||
                      companyName == "Intensify Placements Africa"
                        ? "#ECF8FF"
                        : "#FFFCF5"
                    }`,
                    color: `${
                      companyName === "Coven Work" ||
                      companyName == "Intensify Placements Africa"
                        ? " #1481BA"
                        : "#B54708"
                    }`,
                    padding: "10px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    {logo ? (
                      <img
                        src={logo}
                        alt="img"
                        className={`${
                          companyName === "Oretu LLC" ||
                          companyName === "Rex Technologies Inc"
                            ? "w-12 h-6 bg-black object-center rounded-sm"
                            : companyName === "Coven Work"
                            ? "w-[10rem]"
                            : "w-[5rem]"
                        }`}
                      />
                    ) : (
                      <p
                        style={{
                          color: `${
                            companyName === "Coven Work" ||
                            companyName == "Intensify Placements Africa"
                              ? " #1481BA"
                              : "#B54708"
                          }`,
                          fontWeight: "600",
                          fontSize: "16px",
                        }}
                      >
                        {companyName}
                      </p>
                    )}

                    <p
                      style={{
                        color: `${
                          companyName === "Coven Work" ||
                          companyName == "Intensify Placements Africa"
                            ? " #1481BA"
                            : "#B54708"
                        }`,
                        fontWeight: "300",
                        padding: "10px 0px",
                      }}
                    >
                      {text}
                    </p>
                  </div>

                  {link ? (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        color: `${
                          companyName === "Coven Work" ||
                          companyName == "Intensify Placements Africa"
                            ? " #1481BA"
                            : "#B54708"
                        }`,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(link, "_blank");
                      }}
                    >
                      Learn more <FaArrowRight />
                    </p>
                  ) : (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        color: `${
                          companyName === "Coven Work" ||
                          companyName == "Intensify Placements Africa"
                            ? " #1481BA"
                            : "#B54708"
                        }`,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {}}
                    >
                      Learn more <FaArrowRight />
                    </p>
                  )}
                </div>
              );
            })
          : data.map(({ logo, id, text, companyName, link }) => {
              return (
                <div
                  style={{
                    background: `${
                      companyName === "Coven Work" ? "#ECF8FF" : "#FFFCF5"
                    }`,
                    color: `${
                      companyName === "Coven Work" ? " #1481BA" : "#B54708"
                    }`,
                    padding: "10px",
                  }}
                >
                  <img
                    src={logo}
                    alt="Coven Works"
                    className={`${
                      companyName === "Coven Work" ? "w-[10rem]" : "w-[5rem]"
                    }`}
                  />
                  <p
                    style={{
                      color: `${
                        companyName === "Coven Work" ? " #1481BA" : "#B54708"
                      }`,
                      fontWeight: "300",
                      padding: "10px 0px",
                    }}
                  >
                    {text}
                  </p>

                  {link ? (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        color: `${
                          companyName === "Coven Work" ? " #1481BA" : "#B54708"
                        }`,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        window.open(link, "_blank");
                      }}
                    >
                      Learn more <FaArrowRight />
                    </p>
                  ) : (
                    <p
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        color: `${
                          companyName === "Coven Work" ? " #1481BA" : "#B54708"
                        }`,
                        fontWeight: "600",
                        cursor: "pointer",
                      }}
                      onClick={() => {}}
                    >
                      Learn more <FaArrowRight />
                    </p>
                  )}
                </div>
              );
            })}
        {}
      </div>
    </div>
  );
};
