import { Link } from "react-router-dom";
import { IoIosArrowRoundForward } from "react-icons/io";
import { latestNews, previousNews } from "../constant/AllNewsRoomPost";
import { useState } from "react";
import { Button } from "@material-tailwind/react";

const VestiNews = () => {
  const [count, setCount] = useState(12);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState(previousNews);
  const [searchResult, setSearchResult] = useState("");

  const LoadMore = () => {
    setCount(count + 12);
  };

  // Function to handle search input change
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const applyFilter = () => {
    if (searchValue.trim() === "") {
      setFilteredData(previousNews);
      setSearchResult("");
    } else {
      const filteredTitles = previousNews.filter((item) =>
        item.desc.toLowerCase().includes(searchValue.toLowerCase())
      );
      setSearchResult(filteredTitles.length > 0 ? "" : "No News");
      setFilteredData(filteredTitles);
    }
  };

  return (
    <div className="container mx-auto py-24">
      <div>
        <div className="lg:w-[60%] mx-auto mb-10">
          <h1 className="text-4xl text-headcol font-fontReg pb-2 text-center">
            What's New?
          </h1>
          <p className="font-fontLight text-[#2B5219]  text-[16px] text-center">
            Learn more about the impact of our team and community and how we are
            simplifying immigration and finance for the next generation
            immigrant.
          </p>
        </div>

        <div className="pt-10 pb-24 grid grid-flow-row gap-8 lg:grid-cols-2 lg:gap-16 items-end">
          <div className="rounded-2xl col-span-1">
            <img
              className="w-full h-[27rem]  object-contain"
              src={latestNews.img}
              alt="news-pics"
            />
          </div>
          <div className="pt-12 col-span-1">
            <p className="text-[#3E3E59] text-base font-fontLight pb-8">
              {latestNews.date}
            </p>
            <h1 className="text-5xl text-headcol font-fontBold">
              {latestNews.title}
            </h1>
            <p className="pt-8 pb-12 text-3xl font-fontSemi">
              {latestNews.desc}
            </p>
            <div className="flex items-center text-vestigreen hover:text-headcol font-fontReg hover:font-fontSemi cursor-pointer text-xl lg:w-[50%] w-[90%]">
              <Link
                to={latestNews.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Continue Reading
              </Link>
              <IoIosArrowRoundForward className="text-3xl" />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="lg:w-[60%] mx-auto mt-10">
          <h1 className="text-4xl text-headcol font-fontReg pb-2 text-center">
            Featured
          </h1>
          <p className="font-fontLight text-[#2B5219] text-[16px] text-center">
            Here are some more stories of the seeds we are planting and how we
            remove legal and financial barriers for immigrants
          </p>
        </div>

        <>
          <div className="w-full lg:w-[600px] mx-auto  h-[70px] mt-6 border-[0.5px] border-[#676D7E]  rounded-[5px] flex flex-col justify-center">
            <div className="flex justify-between items-center">
              <input
                type="text"
                placeholder="Search news"
                value={searchValue}
                onChange={handleSearchChange}
                className="p-2 border-[0.5px] rounded-md mx-2 w-full outline-none focus:bg-[#FCFFF9] focus:border-vestigreen"
              />
              {/* <p className="font-[300] ml-2">search</p> */}
              <button
                onClick={applyFilter}
                className="bg-[#66A848] w-[150px] h-[56px] text-white rounded-[5px] mr-2"
              >
                search
              </button>
            </div>
          </div>
        </>
        <div className="pt-10 mx-auto pb-16 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredData.slice(0, count).map((prev, i) => (
            <div key={i} className="h-[32rem]">
              <div className="">
                {prev.img === "" ? (
                  <div className="rounded-2xl w-full h-[17rem] col-span-1 bg-gradient-radial from-green-500 via-green-400 to-green-500 bg-vestigreen border border-[#D8DDFF] mb-3">
                    <div className="py-[58px] px-[67px] flex flex-col justify-center items-center">
                      <h1 className="text-5xl text-white font-fontBold">
                        Vesti
                      </h1>
                      <h1 className="text-5xl text-white font-fontBold">
                        Newsroom
                      </h1>
                    </div>
                  </div>
                ) : (
                  <div className="mb-3 rounded-2xl">
                    <img
                      src={prev.img}
                      className="w-full h-[17rem] object-cover rounded-2xl object-top"
                      alt="article"
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col justify-between h-[40%]">
                <div>
                  <h1 className="text-lg text-headcol font-fontSemi ">
                    {prev.title}
                  </h1>
                  <p className="text-[#FBCB6A] text-base font-bold pt-1">
                    {prev.date}
                  </p>
                  <p className="font-fontReg text-[#2B5219] w-[90%] text-base py-4">
                    {prev.desc}
                  </p>
                </div>
                <div>
                  <div className="w-[60%] lg:w-[55%] flex items-center text-vestigreen font-fontReg hover:font-fontSemi hover:text-headcol cursor-pointer text-base">
                    <Link
                      to={prev.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Continue Reading
                    </Link>
                    <IoIosArrowRoundForward className="text-3xl" />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <p>{searchResult}</p>
      {count < filteredData.length && (
        <div className="text-center">
          <Button
            onClick={LoadMore}
            className="w-[70%] sm:w-[40%] lg:w-[25%] bg-vestigreen p-4 hover:bg-headcol capitalize text-base font-fontLight"
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};

export default VestiNews;
