import React, { useState } from "react";
import { PersonalInfo } from "./personalInfo";
import { Rightside } from "./rightside";
import { SuccessRight } from "./onSucess/successRight";
import newsuccess from "../../assets/logo-icons/newsuccess.svg";
import { useParams, useLocation } from "react-router-dom";

export const PetitionForm = (props) => {
  const [step, setStep] = useState(1);

  let { referrer } = useParams();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const companyRef = queryParams.get("companyRef");

  const [selectedFiles, setSelectedFiles] = useState([{}]);

  const [state, setState] = useState({
    fullName: "",
    email: "",
    dob: "",
    degree: "",
    recruitingCompany: "",
    companyName: "",
    Ein: "",
    EmployerAddress: "",
    employerEmail: "",
    employerContact: "",
  });

  const [isEmptyFieldErrCheck, setIsEmptyFieldErrCheck] = useState(false);

  const Storedpayment = localStorage.getItem("mainStackPayment");

  const submitApplication = async () => {
    var url =
      "https://pathwayapi.wevesti.com/api/v1/pathway/submit-visa-documents-no-auth";

    const formData = new FormData();
    formData.append("immigrationPassport", selectedFiles[0]);
    formData.append("visaType", "H1B");

    // formData.append("email", props.state.email);

    formData.append("submitted", true);
    // formData.append("hasWatchedPathwayVideo", true);

    formData.append(
      "hasCompanyFilling",
      state.recruitingCompany === "I have company filing for me" ? true : false
    );
    formData.append("dateOfBirth", state.dob);
    formData.append("fullname", state.fullName);
    // formData.append("lastName", props.state.lastName);
    formData.append("email", state.email);

    if (referrer) {
      formData.append("referrer", referrer);
    }

    formData.append(
      "hasBachelorsDegree",
      state.degree === "Yes" ? true : false
    );
    // formData.append("middleName", props.state.middleName);
    formData.append(
      "companyFilling",
      state.recruitingCompany === "I have company filing for me"
        ? state.companyName
        : state.recruitingCompany
    );

    if (state.recruitingCompany === "I have company filing for me") {
      formData.append("companyFillingName", state.companyName);
      formData.append("companyFillingEIN", state.Ein);
      formData.append("companyFillingEmail", state.employerEmail);
      formData.append("companyFillingPhoneNumber", state.employerContact);
      formData.append("companyFillingAddress", state.EmployerAddress);
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      setStep(2);
      // props.setStep(2);
      // localStorage.removeItem("mainStackPayment");
    } catch (error) {
      setStep(2);
      console.error("Error uploading file:", error);
      // dispatch(updateUploadedFiles({}));
    }
  };

  const EventHandler = (event) => {
    // Check if the message status indicates payment success
    if (event.data.status === "mainstack-payment-success") {
      // Your logic here for successful payment
      submitApplication();
      localStorage.setItem("mainStackPayment", event.data.status);
    }
    // else {
    //   Handle other messages or statuses if needed
    //   console.log("Received message:", event.data);
    // }
  };

  // Add the event listener to listen for messages
  window.addEventListener("message", EventHandler, false);

  switch (step) {
    case 1:
      return (
        <div
          className="w-full  px-4 md:px-8 py-7  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-24 lg:pt-28  "
          id="h1bFormSection"
        >
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#14290A] font-bold text-[22px]">
              U.S. H1B Petition Stage 1 (Lottery Registration)
            </p>
            <p className="text-[#2B5219] font-bold text-[14px] mt-1">
              Fill out all fields in this form to be entered into the H1B
              Lottery
            </p>

            {/* <p>{companyRef}</p> */}
          </div>

          <div className="flex flex-col bg:flex-row gap-10 w-full mt-12">
            <div className="bg:w-[65%] w-full">
              <PersonalInfo
                state={state}
                setState={setState}
                step={step}
                setStep={setStep}
                isEmptyFieldErrCheck={isEmptyFieldErrCheck}
                setIsEmptyFieldErrCheck={setIsEmptyFieldErrCheck}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                companyRef={companyRef}
              />
            </div>

            {/* {props.step == 2 || Storedpayment === "mainstack-payment-success" ? (
          <div className="bg:w-[35%] w-full">
            <SuccessRight />
          </div>
        ) : ( */}
            <div className="bg:w-[35%] w-full">
              <Rightside
                state={state}
                setState={setState}
                step={step}
                setStep={setStep}
                isEmptyFieldErrCheck={isEmptyFieldErrCheck}
                setIsEmptyFieldErrCheck={setIsEmptyFieldErrCheck}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                companyRef={companyRef}
              />
            </div>
            {/* )} */}
          </div>
        </div>
      );

    case 2:
      return (
        <div
          className="w-full  px-4 md:px-8 py-7  lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-24 lg:pt-28  "
          id="h1bFormSection"
        >
          <div className="flex flex-col justify-center items-center">
            <p className="text-[#14290A] font-bold text-[18px] bg:text-[22px]">
              U.S. H1B Petition Stage 1 (Lottery Registration)
            </p>
          </div>
          <div
            className=" mx-auto w-full  bg:w-[500px]"
            style={{
              marginTop: "30px",
              marginBottom: "30px",
              display: "flex",
              flexDirection: "column",

              gap: "20px",
            }}
          >
            <img src={newsuccess} alt="" />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontSize: "20px",
                  color: "#67A948",
                  textAlign: "center",
                  marginBottom: "1rem",
                }}
              >
                Your H1B Regular Lottery application documents have been
                submitted successfully
              </h1>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "14px",
                  color: "#2B5219",
                }}
              >
                Your documents for the H1B petition have been successfully
                submitted. Complete your Vesti sign up to be able to track
                progress and watch video.
              </p>
            </div>
          </div>

          <div
            onClick={() =>
              window.open("https://app.wevesti.com/register", "_blank")
            }
            className="bg-[#66AA48] cursor-pointer text-white px-12 py-2 w-fit mx-auto rounded-md font-semibold"
          >
            Sign Up
          </div>
        </div>
      );
    default:
      <></>;
  }
};
