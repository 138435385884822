import React, { useState } from 'react'
import { Question } from './PartCards';
import { FiPlus } from "react-icons/fi"
import { FiMinus } from "react-icons/fi";

export const VestiQues = () => {
    
    const [hideAnswer, setHideAnswer] = useState(null)

    const toggleAnswer = (index) => {
        if(hideAnswer === index) {
            setHideAnswer(null)
        } else {
            setHideAnswer(index)
        }
    }

  return (
    <div className='md:flex md:flex-col md:items-center xl:flex xl:flex-col xl:items-center xlg:flex xlg:flex-col xlg:items-center px-4 md:px-[3%] xl:px-[5%] xlg:px-20 xxl:px-[6%] vlg:px-[6%] mb-16'>
        <div className='bg-[#060E42] rounded-md my-10 text-[#FFFFFF] mt-4 xlg:mt-10 p-4 xlg:p-16'>
            <div className='grid sm:grid-cols-2 md:grid-cols-2 xl:grid-cols-2 xlg:grid-cols-2 sm:p-2 md:p-4 xl:p-8 xlg:p-4 h-full'>
                <div className='flex h-full'>
                    <div className='flex-grow'> 
                    <h1 className='text-[30px] w-[100%] sm:w-[80%] md:w-[80%] xl:w-[80%] xlg:w-[80%] pb-6'>Some of the things you may want to know</h1>
                    </div>
                </div>
                <div className='grid h-full'>
                    <div className='flex h-full'>
                        <div className='flex-grow'>
                            {Question.map(({title, ans, border}, index) => {
                                return <div key={index}  className={`${index + 1 === Question?.length ?  '' : 'border-b-2'}   pb-4 mb-4`}>
                                    <div className='flex items-center justify-between'>
                                        <div className='block w-[74%]'>
                                            <h6 className='text-[20px]'>{title}</h6>
                                            {hideAnswer=== index && <p className='py-3'>{ans}</p>}
                                        </div>
                                        <div className=''>
                                            {hideAnswer === index ? (
                                            <FiMinus className='cursor-pointer sm:text-[30px] md:text-[35px] xl:text-[40px] xlg:text-[40px]'
                                            onClick={() => toggleAnswer(index)}
                                            />
                                            ) : (
                                            <FiPlus className='cursor-pointer text-[30px] sm:text-[30px] md:text-[35px] xl:text-[40px] xlg:text-[40px]'
                                            onClick={() => toggleAnswer(index)}
                                            /> 
                                            )}
                                        </div>
                                    </div>
                            </div>
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
