import React from "react";
import cancarepic from "../../assets/CanadianCareGiver/canadaHeroPic.svg";
import canflago from "../../assets/CanadianCareGiver/canflag1.png";
import canflagt from "../../assets/CanadianCareGiver/canflag2.png";
import invesO from "../../assets/CanadianCareGiver/inves.svg";
import investT from "../../assets/CanadianCareGiver/inves2.svg";
import techcity from "../../assets/CanadianCareGiver/TechCity Logo.svg";
import { Button } from "@material-tailwind/react";
import { openLink } from "../../helpers/helpers";

const CanCareHero = (props) => {
  return (
    <div className="bg-[#060E42] sm:mt-[4%] mt-[13%] xl:px-[6%] px-[4%] py-[8%] mb-[6%]">
      <div className="md:flex md:items-center md:justify-between">
        <div className="xxl:w-[50%] xlg:w-[45%] md:w-[50%]">
          <h1 className="vlg:text-[65px] xxl:text-[55px] md:text-[40px] text-[35px] font-bold text-[#ffffff]">
            <span className="text-[#67A948]">Canadian Caregiver Visa:</span>{" "}
            Start Your Journey Towards A Better Future
          </h1>
          <p className="vlg:text-[24px] xxl:text-[20px] text-18px text-[#ffffff] md:mt-1 mt-2">
            The caregiver visa offers more than a job—it’s a path to a better
            future for you and your loved ones. Our experts and technology guide
            you every step for a smooth transition to life in Canada.
          </p>

          {/* <div className="block xs:flex xs:items-center md:mt-[8%] mt-[4%] xxl:gap-10 lg:gap-6 md:gap-2 sm:gap-6 gap-4">
            <div>
              <button className="border-2 rounded-md vlg:px-12 xxxl:px-[64px] xxl:px-[20px] xl:px-[45px] lg:px-[35px] md:px-[20px] sm:px-[50px] xs:px-[8px] px-[44px] py-4 text-[#ffffff] vlg:text-[24px] xxl:text-[20px] sm:text-[18px] text-[14px] xs:mb-0 mb-4 ">
                Speak to an Attorney
              </button>
            </div>

            <div>
              <button className="border-2 border-[#67A948] rounded-md vlg:px-16 xxxl:px-[72px] xxl:px-[30px] xl:px-[55px] lg:px-[45px] md:px-[30px] sm:px-[60px] xs:px-[18px] px-[54px] py-4 bg-[#67A948] text-[#ffffff] vlg:text-[24px] xxl:text-[20px] sm:text-[18px] text-[14px]">
                See If You Qualify
              </button>
            </div>
          </div> */}

          <div className="flex flex-row justify-between gap-4 md:mt-[8%] mt-[4%]">
            <Button
              onClick={() => props.setOpen(true)}
              className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
            >
              See if you qualify
            </Button>
            <Button
              onClick={() =>
                // openLink("https://forms.gle/mXCGXEECqbA2Nz9w7", "_blank")
                openLink("https://paystack.com/buy/canadacare", "_blank")
              }
              // onClick={() => props.setAppointmentModal(true)}
              className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
            >
              Speak To An Expert
            </Button>
          </div>

          <div className="md:mt-[7%] mt-[5%] md:mb-0 mb-[6%] flex items-center xlg:gap-2 gap-1">
            <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
              As featured in:
            </p>
            <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={invesO}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                    "_blank"
                  )
                }
              />

              <img
                className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                src={techcity}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                    "_blank"
                  )
                }
              />

              <img
                className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                src={investT}
                alt=""
                onClick={() =>
                  openLink(
                    "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                    "_blank"
                  )
                }
              />
            </div>
          </div>
        </div>

        <div className="relative flex items-center justify-center">
          <img
            className="vlg:w-[40rem] xxl:w-[33rem] xlg:w-[30rem] lg:w-[25rem] md:w-[20rem] w-[70%] "
            src={cancarepic}
            alt=""
          />
          <img
            className="absolute xxl:top-2 md:top-[-20px] top-[-6px] bottom-0 xxl:left-4 md:left-[200px] sm:left-[120px] xs:left-[100px] left-[60px] xlg:w-[30%] md:w-[30%] w-[22%]"
            src={canflago}
            alt=""
          />
          <img
            className="absolute xxl:bottom-[100px] sm:bottom-[50px] bottom-[40px] xxl:right-3 md:right-[-11px] sm:right-[90px] xs:right-[60px] right-[30px] md:w-[30%] w-[22%]"
            src={canflagt}
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default CanCareHero;
