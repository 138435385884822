import CardAd from "../views/CardAd";
import Offerings from "../views/Offerings";
// import Pathways from "../views/Pathways";
import Showcase from "../views/Showcase";
import WalletCard from "../views/WalletCard";
import OpenAccount from "../views/OpenAccount";
import Testimonials from "../views/Testimonials";
import FaqSection from "../views/FaqSection";
import DownloadApp from "../views/DownloadApp";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import VestiCard from "../assets/images/cards.png";
import PathwaySlides from "../views/PathwaySlides";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";
import {
  Notificationmodal,
  Simplemodal,
} from "../components/helpers/modal/simplifiedmodal";
import { useState, useEffect } from "react";
// import EbookM from "./Ebook";
import popUpIcon from "../assets/popUpIcon/H1bpopup.svg";
import { Platformbutton } from "../components/helpers/button/button";
import { openLink } from "../helpers/helpers";
import { FlightAd } from "../views/flightAd";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import newicon from "../assets/newIcon.svg";
import { RadioOption } from "../components/inputs/radiobutton";
import ngnwallet from "../assets/icons/ngnwallet.svg";
import { useNavigate } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
// import "./slider.css";

const Home = () => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [modalState, setModalState] = useState(false);

  const [showCurrencyModal, setShowCurrencyModal] = useState(false);
  const [option, setOption] = useState("NGN");

  const setPaymentOption = (value) => {
    setOption(value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setOpenModal(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, [modalState]);

  const handleClick = () => {
    setOpenModal(false);
    navigate("/h1b-lottery-payment");
    // setOpenModal(false);

    // openLink(
    //   "https://docs.google.com/forms/d/e/1FAIpQLSdNwK6f7XFIRJNJz-s4zJKdFUYlAxASHY-bevTAMpGKBnMEbg/viewform",
    //   "_blank"
    // );
  };

  const PrevButton = ({ onClick }) => (
    <button
      className="text-[40px] lg:text-[30px]"
      onClick={onClick}
      style={{ position: "absolute", top: "50%", zIndex: "5", left: "20px" }}
    >
      {/* <img src={larrow} alt="" /> */}
      <IoMdArrowDropleftCircle />
    </button>
  );

  const NextButton = ({ onClick }) => (
    <button
      className="text-[40px] lg:text-[30px]"
      onClick={onClick}
      style={{ position: "absolute", top: "50%", zIndex: "5", right: "20px" }}
    >
      <IoMdArrowDroprightCircle />
    </button>
  );

  // https://event.webinarjam.com/register/4/3ypnliq

  const slidersettings = {
    prevArrow: <PrevButton />,
    nextArrow: <NextButton />,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 4000, // Autoplay speed in milliseconds
  };

  return (
    <>
      <Simplemodal
        onClick={() => setShowCurrencyModal(false)}
        open={showCurrencyModal}
      >
        <div className="paymentoption-container">
          <div className="paymentoption-inner">
            <div>
              {/* <h2 className="text-vestigreen">{` Step 1 of 2`}</h2> */}
              <h2 className="font-[600] text-[24px]  text-[#14290A]">
                Select Currency
              </h2>
              <p className="font-[300] text-[#2B5219]">
                Select the currency you want to pay with
              </p>
            </div>

            <div className="paymentoption-inner mt-8 center mb-8">
              <RadioOption
                image={ngnwallet}
                changed={setPaymentOption}
                id="1"
                isSelected={option === "NGN"}
                label="Pay in Naira"
                sublabel="Pay for your flight in Naira"
                value="NGN"
              />
              <div className="mb-4"></div>
              <RadioOption
                image={ngnwallet}
                changed={setPaymentOption}
                id="2"
                isSelected={option === "USD"}
                label="Pay in USD"
                value="USD"
                sublabel="Pay for your flight in Dollars"
              />

              <div className="mb-4"></div>
            </div>

            {option === "NGN" && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(" https://app.wevesti.com/bookflight", "_blank");
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )}

            {option === "USD" && (
              <Platformbutton
                type="normal"
                name="Continue"
                click={() => {
                  window.open(
                    " https://app.wevesti.com/bookflight/usd",
                    "_blank"
                  );
                  setShowCurrencyModal(false);
                }}
                classname="fas fa-arrow-right"
              />
            )}
          </div>
        </div>
      </Simplemodal>

      <Notificationmodal onClick={() => setOpenModal(false)} open={openModal}>
        <LiaTimesSolid
          onClick={() => setOpenModal(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />

        {/* <EbookM setOpenModal={setOpenModal} /> */}
        <div className="w-[100%] sm:w-[400px] md:w-[450px] flex flex-col items-center">
          <img src={popUpIcon} alt="" />
          <p className="text-[#67A948] text-[18px] font-[600] mt-[20px] mb-[15px] text-center">
            Move to the US as an H-1B Worker with minimum HND/Bachelors Degree
          </p>
          <p className="text-[#2B5219] text-[14px] font-[500] text-center mb-[20px]">
            Regular H1B Registration with Vesti for 2025 is now open
          </p>

          <Platformbutton
            name="Start now"
            type="normal"
            disabled={false}
            click={() => {
              handleClick();
            }}
          />
        </div>
      </Notificationmodal>

      <NavBar />

      <Showcase />
      <WalletCard />

      <div className="w-full overflow-x-hidden lg:h-[500px] relative">
        <Slider {...slidersettings}>
          <FlightAd
            leftSide={
              <div className="lg:pl-16 p-6">
                <div className="rounded-[30px] bg-[#060E42] w-[60%] sm:w-[50%] md:w-[40%] lg:w-[40%] flex items-center gap-2 p-2 justify-center">
                  <p className="text-xs  text-white font-medium  font-fontReg">
                    AVAILABLE OFFER
                  </p>
                  <img src={newicon} alt="" className="w-4 h-4" />
                </div>
                <h1 className="text-white text-4xl w-full lg:w-[60%] pt-6 font-fontSemi xxl:w-[70%]">
                  Introducing the new price match offer!.
                </h1>
                <p className="text-base font-extralight text-white pt-6 font-fontReg w-full lg:w-[70%] xxl:w-[80%]">
                  If you find a lower flight fare on another platform, we will
                  not only match it but also offer an even better price.
                </p>
                <div
                  onClick={() => {
                    setShowCurrencyModal(true);
                  }}
                  className="pt-5 pb-8 flex items-center text-[#ffffff] font-fontReg text-xl hover:text-headcol cursor-pointer"
                >
                  <>Book a Flight</>
                  {/* <Link to="/flight">Book a Flight</Link> */}
                  <BsArrowUpRight />
                </div>
              </div>
            }
            image={VestiCard}
            addStyle="flex flex-col lg:flex-row gap-4 lg:justify-between items-center bg-[#004800] rounded-[30px]"
          />

          <CardAd
            leftSide={
              <div className="lg:pl-16 p-6">
                <div className="rounded-[30px] bg-[#060E42] w-[60%] sm:w-[50%] md:w-[40%] lg:w-[40%]">
                  <p className="text-xs mt-8 text-white font-medium text-center p-1 font-fontReg">
                    AVAILABLE TO REQUEST
                  </p>
                </div>
                <h1 className="text-white text-4xl w-full lg:w-[90%] pt-6 font-fontSemi">
                  Introducing the new Vesti GlobalGeng Mastercard.
                </h1>
                <p className="text-base font-extralight text-white pt-6 font-fontReg w-full lg:w-[80%]">
                  Vesti gives humans the power to move overseas to 110
                  Countries.
                </p>
                <div className="pt-5 pb-8 flex items-center text-[#ffffff] font-fontReg text-xl hover:text-headcol">
                  <Link to="/globalgeng-card">Learn more</Link>
                  <BsArrowUpRight />
                </div>
              </div>
            }
            image={VestiCard}
            addStyle="flex flex-col lg:flex-row gap-4 lg:justify-between items-center bg-[#67A948] rounded-[30px]"
          />
        </Slider>
      </div>

      {/* <Pathways /> */}
      <PathwaySlides />
      <Offerings />
      <OpenAccount />
      <Testimonials
        title={
          <>
            <h1 className="text-3xl text-headcol font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-[#2B5219] text-lg font-fontReg">
              Few out of 1000+ stories of Vesti Users
            </p>
          </>
        }
      />
      <FaqSection />
      <DownloadApp />
      <Footer />
    </>
  );
};

export default Home;
