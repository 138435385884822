import React from "react";
import { Button } from "@material-tailwind/react";
import history from "../../assets/images/history.svg";

const EB2history = (props) => {
  return (
    <>
      <div className="m-4 sm:m-8 p-4 bg-secondary rounded-lg mb-8 items-center ">
        <div className="lg:flex lg:justify-between ">
          <div className="lg:w-[70%] flex flex-col items-center sm:flex-row sm:items-start sm:w-full">
            <img
              src={history}
              alt="100+"
              className="mb-2 sm:mb-0 sm:mr-4 w-20 h-20"
            />
            <div className="bg-[#060E42] rounded-lg w-full sm:w-[70%] lg:w-full p-2">
              <p className="text-xl text-white font-fontReg lg:mt-4">
                We have filled over 340+ and counting successful EB2-NIW Visas till
                date.
              </p>
            </div>
          </div>
          <div className="lg:w-[50%] flex justify-center sm:w-full ">
            <Button
              onClick={() => props.setOpen(true)}
              className="font-fontLight text-base capitalize p-4 w-[80%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
            >
              See if you qualify
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EB2history;
