import React from "react";
import caregivPic from "../../assets/CanadianCareGiver/caregivpic.png";

const CanCareSec = (props) => {
  return (
    <div className="xl:px-[6%] px-[4%] mb-[10%] md:mt-0 mt-10 md:my-[7%] md:flex md:items-center lg:gap-[10%] gap-[6%]">
      <img
        className="vlg:w-[50rem] xlg:w-[40rem] lg:w-[30rem] md:w-[22rem] w-[100%] rounded-md"
        src={caregivPic}
        alt=""
      />
      <div>
        <h1 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] md:mt-0 mt-2 font-bold">
          Your Gateway to Work and Residency in Canada
        </h1>
        <p className="text-[#667085] xxl:text-[20px] sm:text-[18px] text-[14px]">
          The Canadian Caregiver Visa enables you to make a meaningful impact by
          caring for those who need it most. In return, you gain access to work
          opportunities, social benefits, and a pathway to permanent residency,
          ensuring you and your family thrive in a new environment.
        </p>
        <button
          onClick={() => {
            props.setOpen(true);
          }}
          className="border border-[#67A948] bg-[#67A948] rounded-md text-[#ffffff] lg:py-[20px] py-[12px] lg:px-[78px] px-[46px] lg:mt-10 mt-4 xxl:text-[20px] sm:text-[18px] text-[14px]"
        >
          Check Eligibility
        </button>
      </div>
    </div>
  );
};

export default CanCareSec;
