import greenTick from "../../assets/CanadianCareGiver/greenTick.png";
import avatarImg from "../../assets/Partnership-Img/AvatarCard.png";

export const BenefitDataF = [
  {
    img: greenTick,
    title: "Career Growth",
    details:
      "Gain access to stable job opportunities in an impactful career path",
  },
  {
    img: greenTick,
    title: "Competitive Salary",
    details:
      "Earn a rewarding income while providing essential care to those in need.",
  },
  {
    img: greenTick,
    title: "Cultural Experience",
    details:
      "Immerse yourself in Canada’s diverse culture and welcoming communities.",
  },
];

export const BenefitDataS = [
  {
    img: greenTick,
    title: "Permanent Residency",
    details:
      "Work toward permanent residency for yourself and your family in Canada.",
  },
  {
    img: greenTick,
    title: "Healthcare Access",
    details:
      "Enjoy Canada’s world-class healthcare system for you and your loved ones.",
  },
  {
    img: greenTick,
    title: "Social Benefits",
    details:
      "Access social programs that support your well-being and long-term stability.",
  },
];

//   Users

export const StartRev = [
  {
    img: avatarImg,
    company: "Damola Oni",
    name: "Jane Smith,",
    location: "XYZ Accelerator",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F7FFF3",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Mainstack",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F6F7FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Wale Yusuff,",
    location: "AltSchool Africa",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#FBF3FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Gova",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F6F7FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Jane Smith,",
    location: "XYZ Accelerator",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#FBF3FF",
  },
  {
    img: avatarImg,
    company: "Joshua Shittabey",
    name: "Jane Smith,",
    location: "XYZ Accelerator",
    details:
      "Partnering with Vesti transformed our accelerator. They provided crucial immigration support and legal guidance for our startups, enabling their growth.",
    background: "#F7FFF3",
  },
];
