import React from 'react'
import { AllTestimonies } from '../constant/TestimonialsData';

export const AllTestimonials = ({ title, link }) => {
    return (
        <div className="w-full   px-4 md:px-8 py-7 lg:py-6 lg:px-20 xxl:px-32  vlg:px-64 pt-12 lg:pt-28 ">
          <div className="vlg:container  vlg:mx-auto flex flex-col items-center">
            <div className="font-fontSemi text-center">{title}</div>
            {/* Comments and Testimonies */}
            <div className="pt-12 pb-20 grid lg:grid-cols-3 md:grid-cols-2 gap-7 font-fontLight">
              {AllTestimonies.map((testi, i) => (
                <div
                  key={i}
                  className="border h-72 rounded-xl px-7 py-5 bg-[#F7FFF3]"
                >
                  <div className="flex pb-3">
                    <div className="">
                      <img src={testi.image} className="rounded-full w-10 h-10" alt="person" />
                      {/* <img src={Image1} alt="person" /> */}
                    </div>
                    <div className="px-3">
                      <p className="text-headcol text-lg font-fontReg">
                        {testi.name}
                      </p>
                      <p className="text-xs font-fontLight text-[#4F4F4F]">
                        {testi.date}
                      </p>
                    </div>
                  </div>
                  <div>
                    <p className="text-headcol text-sm font-fontLight">
                      {testi.testimony}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div className="">{link}</div>
          </div>
        </div>
      );
}
