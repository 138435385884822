import React, { useState } from "react";
import Inputfloat from "../inputs/inputfloat";
import { FileDoc } from "./fileDoc";
import covenLogo from "../../assets/visaForm/covenlabsFullLogo.svg";
import AsraLogo from "../../assets/visaForm/asraFullLogo.png";
import {
  BigIconOption,
  BigIconSingleValue,
  CustomizedSingleselect,
} from "../helpers/singleselect";
import { FillingCompanies } from "./fillingCompany";
import { FormData } from "./onSucess/form";
import { useParams } from "react-router-dom";

export const PersonalInfo = (props) => {
  var setInput = (e) => {
    props.setState({ ...props.state, [e.target.name]: e.target.value });
  };

  var handleSelect = (name, value) => {
    props.setState({ ...props.state, [name]: value });
  };

  const { referrer } = useParams();
  const [option, setOption] = useState(null);

  const setMyOption = (value) => {
    setOption(value);
  };

  var selectOpt = [{ opt: "Yes" }, { opt: "No" }];
  var selectfillingCompany = [
    { opt: "Coven Works", icon: covenLogo },
    { opt: "Asra", icon: AsraLogo },
    { opt: "I have company filing for me", icon: "" },
  ];

  return (
    <div className="">
      {/* <div>
        <p className="text-[#67A948]  font-[500] text-[18px]">
          Personal Information Section
        </p>
        <p className="text-[#2B5219] font-[600] text-[12px]">
          Fill in this form accurately with up to date information
        </p>
      </div> */}

      <FormData
        step={props.step}
        setStep={props.setStep}
        isEmptyFieldErrCheck={props.isEmptyFieldErrCheck}
        selectedFiles={props.selectedFiles}
        setSelectedFiles={props.setSelectedFiles}
        state={props.state}
        setState={props.setState}
        companyRef={props.companyRef}
      />

      {/* <div className="my-8 grid md:grid-cols-2 gap-4 ">
        <Inputfloat
          type="text"
          name="fullName"
          label="FULL NAME (AS WRITTEN ON YOUR PASSPORT)"
          label="FULL NAME (AS WRITTEN ON YOUR PASSPORT)"
          value={props.state.fullName}
          disabled={false}
          placeholder="Full Name"
          onChange={setInput}
        />
        <Inputfloat
          type="text"
          name="email"
          label="EMAIL (AS WRITTEN ON YOUR PASSPORT)"
          value={props.state.email}
          disabled={false}
          placeholder="Email"
          onChange={setInput}
        />
      </div> */}

      {referrer ? (
        <></>
      ) : (
        <div className="hidden bg:block" style={{ marginTop: "20px" }}>
          <FillingCompanies companyRef={props.companyRef} />
        </div>
      )}
    </div>
  );
};
