import React from "react";
import { Claritycall } from "./claritycall";
import NavBar from "../../containers/NavBar";
import Features from "../Features";
import EB2testimony from "../ebnw/EB2testimony";
import FaqSection from "../../views/FaqSection";
import Footer from "../../containers/Footer";
import { useParams } from "react-router-dom";
import { ClarityCallProgress } from "./clarityCallProgress";

export const ClaritycallVisa = () => {
  let { visaType } = useParams();
  const urlparams = new URLSearchParams(window.location.search);

  return (
    <div>
      <NavBar />
      <Claritycall visaType={visaType} urlparams={urlparams} />
      <ClarityCallProgress visaType={visaType} urlparams={urlparams} />
      {/* <Features /> */}
      <EB2testimony
        title={
          <>
            <h1 className="text-3xl text-white font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-white text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <FaqSection />
      <Footer />
    </div>
  );
};
