import React, { useEffect, useState } from "react";
import axios from "axios";
import { Simplemodal } from "../components/helpers/modal/simplifiedmodal";
import EbBanner, { EBeligibility } from "../components/ebvisa/EB1Banner";
import EBpostbanner from "../components/ebvisa/EBpostbanner";
import EBbenefits from "../components/ebvisa/EBbenefits";
import EBedge from "../components/ebvisa/EBedge";
import EBhistory from "../components/ebvisa/EBhistory";
import EBtestimony from "../components/ebvisa/EBtestimony";
import Features from "../components/Features";
import FaqSection from "../views/FaqSection";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";

const EB1visa = () => {
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(
    () => fetchAllCountries(),
    // eslint-disable-next-line
    []
  );

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };

  return (
    <div>
      <NavBar />
      <Simplemodal open={open} onClick={() => setOpen(false)}>
        <EBeligibility countries={countries} close={() => setOpen(false)} />
      </Simplemodal>
      <EbBanner open={open} setOpen={setOpen} />
      <EBpostbanner open={open} setOpen={setOpen} />
      <EBbenefits />
      <EBedge />
      <EBhistory open={open} setOpen={setOpen} />
      <EBtestimony
        title={
          <>
            <h1 className="text-3xl text-white font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-white text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <FaqSection />
      <Footer/>
    </div>
  );
};

export default EB1visa;
