import axios from "axios";
import CardAd from "../views/CardAd";
import Mfries from "../components/Mfries";
import VestiExtras from "../components/VestiExtras";
import { BsArrowUpRight } from "react-icons/bs";
import { Link, useSearchParams } from "react-router-dom";
import Phones from "../assets/images/phones.png";
import Loader from "../assets/logo-icons/loader.svg";
import Testimonials from "../views/Testimonials";
import Featured from "../views/Featured";
import { useEffect, useMemo, useState } from "react";
import MigrationCard from "../components/MigrationCard";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";
import { MigrationFriesData } from "./migrationFriesData";

const categoryLookup = {
  SCHOLARSHIPS: [6, 13],
  JOBS: [6, 18],
  NEWS: [6, 13],
};

const MigrationFries = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [params] = useSearchParams();
  const category = params.get("category") || "JOBS"; /*|| "ALL"*/

  const filteredPosts = useMemo(() => {
    // if (category === "ALL") {
    //   return posts;
    // }
    return posts.filter((post) =>
      post.categories.every((item) => categoryLookup[category].includes(item))
    );
  }, [posts, category]);

  useEffect(() => {
    axios
      .get("https://wevesti.com/wp-json/wp/v2/posts")
      .then(({ data = [] }) => setPosts(data))
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      <NavBar />
      <div className="font-fontReg ">
        <VestiExtras
          title="Migration Fries 🍟"
          date="Want to move overseas in 2024 ? Easily see opportunities and read how to start your migration journey."
          categories={[
            /*"ALL",*/ "JOBS",
            "MIGRATION NEWS",

            "SCHOLARSHIPS" /**/,
          ]}
        />

      <div className=" px-4 md:px-8 lg:px-[8%] xl:px-[7%] xlg:px-[6%] grid grid-cols-1 md:grid-cols-2 xlg:grid-cols-3 justify-between gap-8 pt-12 pb-8">
            {MigrationFriesData?.filter(
              (post) => post.category === category
            ).map((post, index) => (
              <MigrationCard
                key={index}
                img={post.img}
                date={post.date}
                title={post.title}
                desc={post.desc}
                link={post.link}
                category={category}
              />
            ))}
          </div>

       

        <CardAd
          addStyle="flex flex-col lg:flex-row lg:justify-between items-center bg-[#3E6F26] rounded-[30px]"
          leftSide={
              <div className="p-8 lg:pl-16">
                <div className="rounded-[30px] bg-[#060E42] w-[171px]">
                  <p className="text-xs text-white font-fontReg text-center mb-4 p-1">
                    AVAILABLE TO REQUEST
                  </p>
                </div>
                <h1 className="text-white text-[30px] sm:text-4xl xl:w-[360px]">
                  Get a Card and Wallet that makes you happy to spend in store and
                  online
                </h1>
                <p className="text-base font-fontLight text-white pt-6">
                  Vesti gives humans the power to move overseas to 110 Countries.
                </p>
                <div className="pt-[20px] flex items-center text-[#fff] text-xl">
                  <Link to="#">Learn more</Link>
                  <BsArrowUpRight />
                </div>
              </div>
          }
          image={Phones}
        />

        <Testimonials
          title={
            <>
              <h1 className="text-3xl text-headcol font-semibold text-center">
                Read JAPA <span className="text-vestigreen">stories</span>
              </h1>
              <p className="text-center text-[#2B5219] text-lg font-fontLight">
                Read JAPA stories and testimonials from our selected users
              </p>
            </>
          }
          link={
            <div className="flex items-center text-vestigreen font-fontReg hover:text-headcol">
              <Link to="/testimonials">Read more stories</Link>
              <BsArrowUpRight />
            </div>
          }
        />
        {/* <div className="pt-20">
          <Featured />
        </div> */}
      </div>
      <Footer />
    </>
  );
};

export default MigrationFries;
