import React from "react";
import caregivPic from "../../assets/mortgageAndRent/newmigrantHouse.jpg";

const MortgageRentSec = (props) => {
  return (
    <div
      id="vestiHousingLearnmore"
      className="xl:px-[6%] px-[4%] mb-[10%] w-full md:mt-0 mt-10 md:my-[7%] md:flex md:items-center lg:gap-[6%] gap-[6%]"
    >
      <div className="bg-[#050E42] pl-2 pb-2 w-full md:w-[70%] rounded-md">
        <img className=" w-[100%] rounded-md" src={caregivPic} alt="" />
      </div>

      <div className="w-[50%]">
        <h1 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] md:mt-0 mt-2 font-bold">
          Easy Housing Solutions for Immigrants
        </h1>
        <p className="text-[#667085] xxl:text-[20px] sm:text-[18px] text-[14px]">
          Vesti’s Home Financing Solutions empower immigrants with tailored
          mortgage options and seamless rent payment services. Click on the 'Get
          Started' button below, provide the necessary details, and let us guide
          you through a seamless process to achieve your homeownership and
          housing goals in your new country.
        </p>
        <button
          onClick={() => {
            props.setOpen(true);
          }}
          className="border border-[#67A948] bg-[#67A948] rounded-md text-[#ffffff] lg:py-[16px] py-[12px] lg:px-[78px] xlg:px-[80px] px-[46px] lg:mt-10 mt-4 xxl:text-[20px] sm:text-[18px] text-[14px]"
        >
          Get started
        </button>
      </div>
    </div>
  );
};

export default MortgageRentSec;
