import React from "react";
import cancarepic from "../../assets/CanadianCareGiver/canadaHeroPic.svg";
import canflago from "../../assets/CanadianCareGiver/canflag1.png";
import canflagt from "../../assets/CanadianCareGiver/canflag2.png";
import invesO from "../../assets/CanadianCareGiver/inves.svg";
import investT from "../../assets/CanadianCareGiver/inves2.svg";
import techcity from "../../assets/CanadianCareGiver/TechCity Logo.svg";
import { Button } from "@material-tailwind/react";
import { openLink } from "../../helpers/helpers";
import BgPattern from "../../assets/images/pattern-bg.svg";
import GlobalPic from "../../assets/mortgageAndRent/house1.jpg";
import UKLocation from "../../assets/images/uk-loc.png";
import USLocation from "../../assets/images/us-loc.png";
import FINLocation from "../../assets/images/fin-loc.png";
import VestiCentral from "../../assets/images/vesti-loc.png";
import Location4 from "../../assets/images/ctry-loc.png";
import { Link } from "react-router-dom";
import "./index.css";
import { Link as AnchorLink } from "react-scroll";

const MortgageHousingHero = (props) => {
  return (
    <div className="bg-[#060E42] sm:mt-[4%] mt-[13%] xl:px-[6%] px-[4%] py-[8%] mb-[6%] relative overflow-hidden">
      <img
        src={BgPattern}
        alt="pattern"
        className="absolute right-[-100%] top-0  lg:right-0"
      />
      <div className="">
        <div className="flex flex-col lg:flex-row justify-between items-center">
          <div className="xxl:w-[50%] xlg:w-[45%] md:w-[50%]">
            <h1 className="vlg:text-[65px] xxl:text-[55px] md:text-[40px] text-[35px] font-bold text-[#ffffff]">
              <span className="text-[#67A948]">Home Financing solutions</span>{" "}
              Tailored for Immigrants
            </h1>
            <p className="vlg:text-[24px] xxl:text-[20px] text-18px text-[#ffffff] md:mt-1 mt-2">
              Join thousands of immigrants using Vesti to access mortgages, pay
              rent seamlessly, and achieve their homeownership dreams in new
              countries.
            </p>
            <div className="flex flex-row justify-between gap-4 md:mt-[8%] mt-[4%]">
              <AnchorLink
                spy={true}
                smooth={true}
                to="vestiHousingLearnmore"
                className="font-fontLight text-base rounded-md capitalize p-5 mb-16 w-[90%] sm:w-[60%] text-center cursor-pointer lg:w-[50%] border text-white border-vestigreen bg-transparent transition duration-700 ease-in-out"
              >
                Learn How It Works
              </AnchorLink>
              {/* <Button
                onClick={() => props.setOpen(true)}
                className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
              >
                Learn How It Works
              </Button> */}
              <Button
                // onClick={() =>
                //   openLink("https://forms.gle/mXCGXEECqbA2Nz9w7", "_blank")
                //   openLink("https://paystack.com/buy/canadacare", "_blank")
                // }
                onClick={() => props.setOpen(true)}
                className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
              >
                Get Started
              </Button>
            </div>

            <div className="md:mt-[7%] mt-[5%] md:mb-0 mb-[6%] flex items-center xlg:gap-2 gap-1">
              <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
                As featured in:
              </p>
              <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
                <img
                  className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                  src={invesO}
                  alt=""
                  onClick={() =>
                    openLink(
                      "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                      "_blank"
                    )
                  }
                />

                <img
                  className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                  src={techcity}
                  alt=""
                  onClick={() =>
                    openLink(
                      "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                      "_blank"
                    )
                  }
                />

                <img
                  className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                  src={investT}
                  alt=""
                  onClick={() =>
                    openLink(
                      "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="lg:w-[50%]  z-30 flex  justify-end items-end">
            <div className="w-[25rem] relative xl:w-[30rem] xlg:w-[32rem] xlg:h-[32rem] xxl:w-[35rem] xxl:h-[35rem] xl:h-[30rem] h-[25rem] overflow-hidden ">
              <div className="w-full h-full p-2 rounded-[100%] custom-dashed">
                <img
                  className="w-full h-full object-cover rounded-[100%]"
                  src={GlobalPic}
                  alt="global"
                />
              </div>

              <img
                className="absolute top-[7rem] left-[-22px] w-28 Housinganimate-bounce"
                src={UKLocation}
                alt="global"
              />
              <img
                className="absolute top-4 right-4 w-28 Housinganimate-bounce"
                src={USLocation}
                alt="global"
              />
              <img
                className="absolute bottom-4 left-16 w-16 Housinganimate-bounce"
                src={FINLocation}
                alt="global"
              />
              {/* <img
              className="absolute top-32 right-32 lg:top-64 lg:right-64 md:top-72 md:right-64 sm:top-60 sm:right-60 w-16"
              src={VestiCentral}
              alt="global"
            /> */}
              <img
                className="absolute bottom-16 right-0 xl:right-[-20px] w-28 Housinganimate-bounce"
                src={Location4}
                alt="global"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MortgageHousingHero;
