import React from "react";
import { VisaClarityCallPricing } from "../../constant/TestimonialsData";
import { Link as AnchorLink } from "react-scroll";

const ClarityPricing = ({ title }) => {
  return (
    <div className="w-full pb-6 ">
      <div className="container mx-auto flex flex-col items-center w-[80%] lg:p-[4.5rem]">
        <div className="font-fontSemi text-center">{title}</div>

        <div className="pt-12 lg:pb-20 grid lg:grid-cols-3 md:grid-cols-2 gap-7 font-fontLight">
          {VisaClarityCallPricing?.map((item, i) => (
            <div
              key={i}
              className="border-2 border-black-300 h-90 lg:h-70 rounded-xl px-7 py-5"
              style={{ backgroundColor: item.backgroundColor }}
            >
              <div className="flex pb-3">
                <p className="text-headcol text-lg font-fontReg">
                  {item.title}
                </p>
              </div>
              <p
                className={`text-lg font-fontLight`}
                style={{ color: item.textColor }}
              >
                {item.description}
              </p>
              <div>
                <p
                  className={`text-2xl font-bold mt-12`}
                  style={{ color: item.priceColor }}
                >
                  {item.price}
                </p>
              </div>
            </div>
          ))}
        </div>
        <AnchorLink
          spy={true}
          smooth={true}
          to="bookcallSection"
          className="normal-case text-white bg:w-[25%] inline-block text-center bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px] rounded-lg cursor-pointer"
        >
          Book a Clarity Call
        </AnchorLink>
      </div>
    </div>
  );
};

export default ClarityPricing;
