import React, { useEffect, useState } from "react";
import axios from "axios";
import { Simplemodal } from "../components/helpers/modal/simplifiedmodal";
import Features from "../components/Features";
import EB2Banner, { EB2eligibility } from "../components/ebnw/EB2Banner";
import EB2postbanner from "../components/ebnw/EB2postbanner";
import EB2benefits from "../components/ebnw/EB2benefits";
import EB2edge from "../components/ebnw/EB2edge";
import EB2history from "../components/ebnw/EB2history";
import EB2testimony from "../components/ebnw/EB2testimony";
import FaqSection from "../views/FaqSection";
import NavBar from "../containers/NavBar";
import Footer from "../containers/Footer";

const EBNIW = () => {
  const [open, setOpen] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(() => fetchAllCountries(), []);

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };

  return (
    <div>
      <NavBar />
      <Simplemodal open={open} onClick={() => setOpen(false)}>
        <EB2eligibility countries={countries} close={() => setOpen(false)} />
      </Simplemodal>

      <EB2Banner
        open={open}
        setOpen={setOpen}
        appointmentModal={appointmentModal}
        setAppointmentModal={setAppointmentModal}
      />
      <EB2postbanner open={open} setOpen={setOpen} />
      <EB2benefits />
      <EB2edge />
      <EB2history open={open} setOpen={setOpen} />
      {/* <Features /> */}
      <EB2testimony
        title={
          <>
            <h1 className="text-3xl text-white font-fontSemi text-center">
              What our users are <span className="text-vestigreen">saying</span>
            </h1>
            <p className="text-center text-white text-lg font-fontReg">
              Don't take our words for it, see what our users are saying.
            </p>
          </>
        }
      />
      <FaqSection />
      <Footer />
    </div>
  );
};

export default EBNIW;
