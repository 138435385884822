import TeamMember from "../assets/images/teammember.png";
import TeamMember1 from "../assets/images/teammember1.png";
import TeamMember2 from "../assets/team/victoria.jpeg";
import TeamMember3 from "../assets/images/teammember3.png";
import TeamMember4 from "../assets/team/damolaOni.jpg";
import TeamMember5 from "../assets/team/paul.jpeg";
import TeamMember6 from "../assets/images/teammember6.png";
import TeamMember7 from "../assets/images/teammember7.png";
import TeamMember9 from "../assets/images/teammember9.png";
import TeamMember10 from "../assets/images/teammember10.png";
import nickImg from "../assets/images/nickpic 2.jpg"
// import TeamMember8 from "../assets/images/teammember8.png";
import TeamMember11 from "../assets/images/teammember11.png";
import bunmiImg from "../assets/images/bunmiPic.jpg"
import kingsleypic from "../assets/images/Kingsleypic.png"
import abayomipic from "../assets/images/Abayomi.jpg"
import avatarImg from "../assets/Partnership-Img/AvatarCard.png";
import boniface from "../assets/team/Boniface.png"
import fisola from "../assets/team/fisola.png"
import maryam from "../assets/team/Maryam.png"
import ope from "../assets/team/OpeOdunlani.png"
import Mimi from "../assets/team/MImi.jpg"
import ovie from "../assets/team/Ovie Victor.png"
import malik from "../assets/team/Malik Akande.jpg"
import chris from "../assets/team/Ebata Chris.png"
import samuel from "../assets/team/Samuel.jpg"
import tosin from "../assets/team/TosinOpe.jpg"
import akpan from "../assets/team/Akpan.jpg"
import zainabAbz from "../assets/team/Zainab Abdulaziz.jpg"
import peter from "../assets/team/Peter Jimoh.jpg"
import zainabBak from "../assets/team/ZainabBakare.jpg"
import victoriaEk from "../assets/team/Victoria Ekperezie.jpg"
import emmanuelN from "../assets/team/Emmanuel Nwafor.jpg"
import natasha from "../assets/team/Natasha.jpg"
import farida from "../assets/team/Ferideh Sarumi.jpg"
import anu from "../assets/team/aanu.JPG"
import peach from "../assets/team/Peach.JPG"
import niyi from "../assets/team/Olaniyi Bolaji.png"
import charles from "../assets/team/Charles.jpg"
import raphael from "../assets/team/Rapheal.jpg"
import austin from "../assets/team/Austin.png"
import adewale from "../assets/team/Adewale.png"
import waheed from "../assets/team/Waheed.png"
import ebenezer from "../assets/team/Ebenezer 2.jpeg"
import bimpe from "../assets/team/Adebimpe.jpg"
import doyin from "../assets/team/Doyin.jpg"
import Steve from "../assets/team/StephenK.jpg"
import debby from "../assets/team/Debby.jpg"
import tosinade from "../assets/team/tosinade.jpg"
import johntom from "../assets/team/JohnTom 2.jpg"
import yemi from "../assets/team/Yemi.jpeg"
import abigirlpic from "../assets/team/Abigirl.JPG"
import chidinma from "../assets/team/Chidinma.JPG"
import eniola from "../assets/team/Akinleye Eniola.png"
import theresa from "../assets/team/theresa.jpg"
import hr from "../assets/team/HR.JPG"
import ngozi from "../assets/team/NgoziJPG.JPG"
import vero from "../assets/team/ Veronica.JPG"
import ralphN from "../assets/team/ralphN.jpeg"
import paula from "../assets/team/PAULA.JPG"
import stellaM from "../assets/team/stellaM.jpg"
import nkechi from "../assets/team/Nkechi.jpg"
import femi from "../assets/team/Femi.jpg"
import segun from "../assets/team/Segun.jpg"
import williams from "../assets/team/Williams.jpg"
import pricilla from "../assets/team/Pricilla.jpg"
import ogunlade from "../assets/team/ogunlade.jpg"
import Hilda from "../assets/team/Hilda.jpg"
import angelica from "../assets/team/Angelica.jpg"
import kasoope from "../assets/team/Kasoope.jpg"

export const People = [
  {
    headShot: TeamMember,
    fullName: "Olusola Amusan",
    designation: "CEO/Co-Founder",
    socialLink1: "https://www.linkedin.com/in/olusolaamusan",
    socialLink2: "http://www.twitter.com/olusolamusan",
  },
  {
    headShot: TeamMember1,
    fullName: "Bimbola Amusan",
    designation: "COO/Co-Founder",
    socialLink1: "https://www.linkedin.com/in/abimbola-amusan-jd-116653aa",
    socialLink2: "http://www.twitter.com/bimboamusanesq",
  },
  {
    headShot: nickImg,
    fullName: "Nicholas Bassey",
    designation: "Engineering Lead",
    socialLink1: "https://www.linkedin.com/in/nicholasbassey/",
    socialLink2: "https://x.com/nickyawat",
  },
  {
    headShot: TeamMember4,
    fullName: "Damola Oni",
    designation: "Legal Affairs Manager",
    socialLink1: "https://www.linkedin.com/in/adedamola-oni-cams-886b7b145/",
    socialLink2: "http://www.twitter.com/Damite2ky",
  },
  {
    headShot: ogunlade,
    fullName: "Temitayo Ogunlade",
    designation: "Senior Finance Manager",
    socialLink1: "https://www.linkedin.com/in/temitayo-ogunlade-b62344163?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "https://x.com/onlytemisky?s=21&t=w3bfYqFAknVEM8okAe-v0A",
  },
  {
    headShot: hr,
    fullName: "Temiloluwa Odekunle",
    designation: "HR Lead",
    socialLink1: "https://www.linkedin.com/in/odekunle-temiloluwa-acipm-hrpl-6364ba129?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "https://x.com/adebayo_t90?s=21&t=w3bfYqFAknVEM8okAe-v0A",
  },
  {
    headShot: TeamMember7,
    fullName: "Ifeoluwa Ariyo-Agbaje",
    designation: "Communications Lead",
    socialLink1: "http://www.linkedin.com/in/agbajegoriite",
    socialLink2: "http://www.twitter.com/agbajegoriite",
  },
  {
    headShot: abayomipic,
    fullName: "Abayomi Oluyede",
    designation: "Deputy Operations Lead",
    socialLink1: "https://www.linkedin.com/in/abayomi-oluyede/",
    socialLink2: "https://x.com/princeaby__",
  },
  {
    headShot: kingsleypic,
    fullName: "Kingsley Ekokotu",
    designation: "Deputy Operations Lead",
    socialLink1: "https://www.linkedin.com/in/kingsley-ekokotu-b84793174/",
    socialLink2: "https://x.com/DaddySlim_K",
  },
  {
    headShot: bunmiImg ,
    fullName: "Olubunmi Opadoyin",
    designation: "Senior Business & Migration Analyst ",
    socialLink1: "https://www.linkedin.com/in/olubunmi-opadoyin-8838a4185/",
    socialLink2: "https://x.com/Olubunmi_Elish",
  },
  {
    headShot: TeamMember11,
    fullName: "Opeyemi Balogun",
    designation: "Customer Success Lead",
    socialLink1: "https://www.linkedin.com/in/opeyemi-balogun-/",
    socialLink2: "https://x.com/boosgold",
  },
];

export const EngineeringTeam = [
  {
    img: austin,
    fullName: "Austin Emenike",
    designation: "Front End Engineer",
    socialLink1: "https://www.linkedin.com/in/ebube-emenike-b3447823b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img:adewale,
    fullName: "Adewale Adeosun",
    designation: "Mobile Developer Lead",
    socialLink1: "https://www.linkedin.com/in/adewalevic/",
    socialLink2: "",
  },
  {
    img: waheed,
    fullName: "Olalekan Waheed",
    designation: "Mobile Developer",
    socialLink1: "https://www.linkedin.com/in/waheed-olalekan-728bab214?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: yemi,
    fullName: "Yemi Folayan",
    designation: "Back End Engineer",
    socialLink1: "https://www.linkedin.com/in/folayan-yemi/",
    socialLink2: "",
  },
  {
    img: peach,
    fullName: "Peace Edak",
    designation: "Front End Developer Intern",
    socialLink1: "https://www.linkedin.com/in/peace-edak-56a751240?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: ebenezer,
    fullName: "Ebenezer Hunsu",
    designation: "Quality Assurance Intern",
    socialLink1: "https://www.linkedin.com/in/hunsu?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: johntom,
    fullName: "John Tom",
    designation: "Back End Engineer",
    socialLink1: "https://www.linkedin.com/in/john-tom-728183185?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: tosinade,
    fullName: "Oluwatosin Adegoroye",
    designation: "Front End Developer Intern",
    socialLink1: "https://www.linkedin.com/in/tosin-adegoroye?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: Steve,
    fullName: "Stephen Kofoworola",
    designation: "Back End Developer",
    socialLink1: "https://www.linkedin.com/in/stephen-kofoworola-536483145?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: raphael,
    fullName: "Raphael Odara",
    designation: "DevOps Engineer",
    socialLink1: "https://www.linkedin.com/in/odara-rapheal?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: charles,
    fullName: "Oluwafemi-Ezra Charles",
    designation: "IT Support",
    socialLink1: "https://www.linkedin.com/in/oluwafemi-ezra-charles-85771219b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: abigirlpic,
    fullName: "Abigirl Taiwo",
    designation: "Fraud Analyst",
    socialLink1: "https://www.linkedin.com/in/abigail-taiwo-945b55189?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: niyi,
    fullName: "Olaniyi Bolaji",
    designation: "Cards Manager",
    socialLink1: "https://www.linkedin.com/in/olaniyi-bolaji-8b6a631b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
];

export const OperationsTeam = [
  {
    img: natasha,
    fullName: "Natasha Adolphus",
    designation: "EA to the CEO",
    socialLink1: "https://www.linkedin.com/in/natashadolphs1554?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
    socialLink2: "",
  },
  {
    img: anu,
    fullName: "Aanuoluwapo Olatunde",
    designation: "EA to the COO",
    socialLink1: "https://www.linkedin.com/in/aanuoluwapo-aat-aca-12621b23a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: debby,
    fullName: "Deborah Aladekoye",
    designation: "Customer Success Associate",
    socialLink1: "https://www.linkedin.com/in/aladekoyedeborah?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: doyin,
    fullName: "Adedoyin Adewale",
    designation: "Customer Success Associate",
    socialLink1: "https://www.linkedin.com/in/adedoyin-esther-adewale?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: chidinma,
    fullName: "Chidinma Iwu",
    designation: "Customer Success Associate",
    socialLink1: "https://www.linkedin.com/in/victoria-chidinma-iwu-146646248?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: theresa,
    fullName: "Theresa Agwu",
    designation: "Customer Success Associate",
    socialLink1: "https://www.linkedin.com/in/theresa-o-agwu-72a846151?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: pricilla,
    fullName: "Priscilla Owen",
    designation: "Admin Officer",
    socialLink1: "https://www.linkedin.com/in/owen-olabisi-68a1881b3?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: ngozi,
    fullName: "Ngozi Ekeghasi",
    designation: "Admin Officer",
    socialLink1: "https://www.linkedin.com/in/ngozi-ekeghasi-089b6b166?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: Hilda,
    fullName: "Hilda Acheampong",
    designation: "Ghana Growth Marketing Lead ",
    socialLink1: "https://www.linkedin.com/in/hilda-acheampong-5bb436247?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: eniola,
    fullName: "Eniola Akinleye",
    designation: "Community Manager",
    socialLink1: "https://www.linkedin.com/in/ayoola-m-akinleye-b58689138?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: farida,
    fullName: "Farida Sarumi",
    designation: "Finance Officer",
    socialLink1: "https://www.linkedin.com/in/faridah-sarumi-024a6a236?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: stellaM,
    fullName: "Stellamaris Achebe",
    designation: "Finance Officer",
    socialLink1: "https://www.linkedin.com/in/stellamaris-achebe?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
];

export const GlobalServiceDeliveryTeam = [
  {
    img: paula,
    fullName: "Paula Ayeni",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/paula-ayeni-06a30419b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: bimpe,
    fullName: "Adebimpe Idowu",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/adebimpe-i-523926251?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: nkechi,
    fullName: "Gloria Nkechiynere",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/nkechinyere-gloria-okalla-6008a8234?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: vero,
    fullName: "Veronica Adukwulu",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/veronica-adukwulu-0878ab167?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: emmanuelN,
    fullName: "Emmanuel Nwafor",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/ifeanyichukwu-nwafor-b3180819b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: zainabBak,
    fullName: "Zainab Bakare",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/zainabbakaree?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: angelica,
    fullName: "Angelica Lowe",
    designation: "Paralegal (Dallas)",
    socialLink1: "",
    socialLink2: "",
  },
  {
    img: segun,
    fullName: "Oluwasegun David Fasasi",
    designation: "Migration Laison Officer",
    socialLink1: "https://www.linkedin.com/in/oluwasegun-d-fasasi?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: femi,
    fullName: "Oluwafemi Adewole",
    designation: "Migration Laison Officer",
    socialLink1: "https://www.linkedin.com/in/adewole-oluwafemi-70667a199?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img:victoriaEk,
    fullName: "Victoria Ekperezie",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/victoria-ekperezie-72b99a201?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: peter,
    fullName: "Peter Jimoh",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/peter-jimoh-070897183?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: zainabAbz,
    fullName: "Zainab Abdulaziz",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/zainab-abdulaziz-0581a4221?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: kasoope,
    fullName: "Kasopefoluwa Amusan",
    designation: "Business Analyst Intern",
    socialLink1: "https://www.linkedin.com/in/kasopefoluwa-amusan-311803298?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: williams,
    fullName: "Stephen Williams",
    designation: "Migration Liaison Officer",
    socialLink1: "https://www.linkedin.com/in/stephen-adekunle-williams?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: akpan,
    fullName: "Inyene Akpan",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/inyene-dominic-7991a916a?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: tosin,
    fullName: "Oluwatosin Olaoye",
    designation: "Legal Officer",
    socialLink1: "https://www.linkedin.com/in/oluwatosin-olaoye-a291aa255?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
];

export const ProductCreativeTeam = [
  {
    img: fisola,
    fullName: "Adefisola Bamisile",
    designation: "Product Manager",
    socialLink1: "https://www.linkedin.com/in/adefisola-bamisile?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: chris,
    fullName: "Chris-Nosa Ebata",
    designation: "Product Manager Intern",
    socialLink1: "https://www.linkedin.com/in/chris-ebata-a24206339?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img:boniface,
    fullName: "Boniface Usheshe",
    designation: "Creative Lead",
    socialLink1: "https://www.linkedin.com/in/boniface-usheshe-54a9051a8?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: maryam,
    fullName: "Maryam Yahaya",
    designation: "Product Designer",
    socialLink1: "https://www.linkedin.com/in/maryam-akanbi?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: ope,
    fullName: "Opeoluwa Odunlami",
    designation: "Product Designer",
    socialLink1: "https://www.linkedin.com/in/opeodunlami?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: Mimi,
    fullName: "Mimi-Stephanie Ugeh",
    designation: "Product Designer",
    socialLink1: "https://www.linkedin.com/in/mimistephanieugeh?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: ovie,
    fullName: "Ovie Victor",
    designation: "Product Designer",
    socialLink1: "https://www.linkedin.com/in/ovie-victor-oghoghorie-8b8905269?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: malik,
    fullName: "Malik Akande",
    designation: "Product Designer",
    socialLink1: "https://www.linkedin.com/in/malik-akande-522196268?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: ralphN,
    fullName: "Raphael Nwogha",
    designation: "Creative designer",
    socialLink1: "https://www.linkedin.com/in/raphael-nwogha?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  {
    img: samuel,
    fullName: "Samuel Ariyo",
    designation: "Product Designer",
    socialLink1: "https://www.linkedin.com/in/ariyo-samuel-36b893198?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
    socialLink2: "",
  },
  
];