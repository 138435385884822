import React, { useEffect, useState } from "react";
import axios from "axios";
import NavBar from "../../containers/NavBar";
import Footer from "../../containers/Footer";
import CanCareHero from "./CanCareHero";
import { VestiQues } from "../Partnership/VestiQues";
import { PartNum } from "../Partnership/PartNum";
import CanCareSec from "./CanCareSec";
import CanBenefits from "./CanBenefits";
import {
  Simplemodal,
  Notificationmodal,
} from "../helpers/modal/simplifiedmodal";
import { EB2eligibility } from "../ebnw/EB2Banner";
import { CareGiverEligibilityTest } from "../CareGiverEligtest";
import { LiaTimesSolid } from "react-icons/lia";
import Users from "./Users";
import { Reviews } from "../Partnership/Reviews";

const CanadianCareGivVisa = () => {
  const [open, setOpen] = useState(false);
  const [appointmentModal, setAppointmentModal] = useState(false);
  const [countries, setCountries] = useState(false);

  useEffect(() => fetchAllCountries(), []);

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };
  return (
    <div>
      <Notificationmodal open={open} onClick={() => setOpen(false)}>
        <LiaTimesSolid
          onClick={() => setOpen(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />
        <div className="w-[100%] sm:w-[400px] md:w-[450px] flex flex-col items-center">
          <CareGiverEligibilityTest
            countries={countries}
            close={() => setOpen(false)}
          />
        </div>
      </Notificationmodal>

      <NavBar />
      <CanCareHero
        open={open}
        setOpen={setOpen}
        appointmentModal={appointmentModal}
        setAppointmentModal={setAppointmentModal}
        countries={countries}
        setCountries={setCountries}
      />
      <CanCareSec open={open} setOpen={setOpen} />
      <PartNum />
      <CanBenefits />
      <Users />
      <VestiQues />
      <Footer />
    </div>
  );
};

export default CanadianCareGivVisa;
